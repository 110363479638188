import React, {useState} from 'react'
import { Button, Modal } from 'react-bootstrap'
import errorIcon from '../../assets/error-triangle.svg'
import { useAppContext } from '../../AppContext'
import sad from '../../assets/sad-face.png'
import { trackEvent } from '../../mixpanel'
import {useNavigate} from 'react-router-dom'
import productRoute from '../../services/productRoute'
import adminRoute from '../../services/adminRoute'
import orderRoute from '../../services/orderRoute'

const DeleteProductModal = ({item,itemType,actionType}) => {
  const {showDeleteModal, setShowDeleteModal, showToast}= useAppContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {deleteProduct,deleteCategory, deactivateBoxTemplate,deactivateVoucherTemplate, deactivateSubscription,deactivateVoucher}= productRoute()
  const {deactivatePromo,deletePromo,activatePromo}= adminRoute()
  const {deleteOrder}= orderRoute()
const handleDelete = () => {
  if (itemType === 'product'){
  const productId = item?.id;
 
  deleteProduct (
    productId,
    setShowDeleteModal,
    setLoading,
    showToast )
  }
  else  if (itemType === 'order'){
    const orderId = item?._id || item?.id;
   
     deleteOrder(
      orderId,
      setShowDeleteModal,
      setLoading,
      showToast )
    }

  else if (itemType === 'category'){
    const categoryId = item?._id;
    let body = {};
      if (item?.status === 'active')
      {
        body.status = 'inactive'
      }else if (item?.status === 'inactive'){
      body.status = 'active'
      }else{
        body.status = 'inactive'
      }
    

    deleteCategory (
      categoryId,
      body,
      setShowDeleteModal,
      setLoading,
      showToast )
    }


   else if (itemType === 'promo'){
    const promoId = item?.id;
    if (item?.status === 'active') {
    
      deactivatePromo (
        promoId,
        setShowDeleteModal,
        setLoading,
        showToast )
    } else if (item?.status === 'inactive') {
     
      activatePromo (
        promoId,
        setShowDeleteModal,
        setLoading,
        showToast )
    }
   
   
    }

         else if (itemType === 'boxTemplate'){
        const boxTemplateId = item?.id;
       
        deactivateBoxTemplate (
          boxTemplateId,
          setShowDeleteModal,
          setLoading,
          showToast )
        }

       else if (itemType === 'voucherTemplate'){
          const voucherTemplateId = item?.id;
         
          deactivateVoucherTemplate (
            voucherTemplateId,
            setShowDeleteModal,
            setLoading,
            showToast )
          }

          else if (itemType === 'subscription'){
            const boxId = item?._id;
            let body = {};
            if (item?.status === 'active')
            {
              body.status = 'inactive'
            }else if (item?.status === 'inactive'){
            body.status = 'active'
            }else{
              body.status = 'inactive'
            }
          
           
            deactivateSubscription (
              boxId,
              body,
              setShowDeleteModal,
              setLoading,
              showToast )
            }
            else if (itemType === 'voucher'){
              const voucherId = item?._id;
              let body = {};
              if (item?.isActive === true)
              {
                body.isActive = false
              }else if (item?.isActive === false){
              body.isActive = true
              }else{
                body.isActive = false
              }
            
              deactivateVoucher (
                voucherId,
                body,
                setShowDeleteModal,
                setLoading,
                showToast )
              }
  else if (itemType === 'customer' || itemType === 'customer-account'){

  setShowDeleteModal(false);
    }
    else if (itemType === 'admin'){
   
      setShowDeleteModal(false);
        }

 
 
};
const handleCancel = () => {
  
  setShowDeleteModal(false);

 
};

  const onHide = ()=>{
    setShowDeleteModal(false)
  
  }


return (
  <Modal show={showDeleteModal} onHide={onHide} centered>
      <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='d-flex flex-column justify-content-center text-center gap-3 px-5 pb-5'>
         <div className='text-center'> <img src={errorIcon} style={{width:'70px'}}/></div>
         {itemType === 'product' ?
         <div className=''>

         
         <h4 style={{color:'#00000', fontWeight:'500'}}>Are you sure you want to delete this product?</h4>
         <h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>{item?.productName}</h4>
         </div> : 
         itemType === 'customer-account' ?
         <div className=''>
<h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>Delete Account</h4>
         
         <h4 style={{color:'#00000', fontWeight:'500'}}>You are about to delete the user account with email address</h4>
         <h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>{item?.email}</h4>
         </div> :
          itemType === 'admin' ? 
         <div className=''>
         <h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>Deactivate User</h4>
         
<h4 style={{color:'#00000', fontWeight:'500'}}>You are about to deactivate a user, are you sure you want to proceed?</h4>
<h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>{item?.email}</h4>
</div>
         :
          itemType === 'customer' ? 
         <div className=''>
         <h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>Deactivate User</h4>
         
<h4 style={{color:'#00000', fontWeight:'500'}}>You are about to deactivate a user, are you sure you want to proceed?</h4>
<h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>{item?.email}</h4>
</div>
         :

itemType === 'boxTemplate' || itemType === 'voucherTemplate'  ?
         <div className=''>
<h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>Delete {itemType === 'boxTemplate' && 'Box Template'} {itemType === 'voucherTemplate' && 'Voucher Template'}   </h4>
         
         <h4 style={{color:'#00000', fontWeight:'500'}}>You are about to delete <br/>
         <b>{item?.groupName || item?.name}</b>
         <br/>
         Are you sure you want to proceed?</h4>
        
         </div> :
         itemType === 'subscription' || itemType === 'promo'
            ?
         <div className=''>
<h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>{ item?.status === 'inactive' ? 'Activate' : 'Deactivate'}  {itemType === 'subscription' && 'Subscription Box'} {itemType === 'promo' && 'Promotion'}  </h4>
         
         <h4 style={{color:'#00000', fontWeight:'500'}}>You are about to {item?.status === 'inactive' ? 'activate' : 'deactivate'}  <br/>
         <b>{item?.name}</b>
         <br/>
         Are you sure you want to proceed?</h4>
        
         </div> 
          :
           itemType === 'voucher'
             ?
          <div className=''>
 <h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>{ item?.isActive === false ? 'Activate' : 'Deactivate'} Voucher  </h4>
        <h4 style={{color:'#00000', fontWeight:'500'}}>You are about to {item?.isActive === false ? 'activate' : 'deactivate'}
         
          <br/>
          Are you sure you want to proceed?</h4>
         
          </div> 
         :
          itemType === 'order' ? 
<div className=''>
  <h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>Delete Order</h4>
  <h4 style={{color:'#00000', fontWeight:'500'}}>You are about to delete this order, are you sure you want to proceed?</h4>
</div>
:  itemType === 'category' ? 
<div className=''>
<h4 style={{color:'#00000', fontWeight:'600'}} className='mt-3'>{item?.status === 'inactive' ? 'Activate' : 'Deactivate'} Category</h4>
<h4 style={{color:'#00000', fontWeight:'500'}}>You are about to {item?.status === 'inactive' ? 'activate' : 'deactivate'}  <br/>
         <b>{item?.categoryName}</b>
         <br/>
         Are you sure you want to proceed?</h4>
</div>
: null}

          
          
          <div className='d-flex gap-3 text-center justify-content-center'>
          <Button variant='outline-danger' onClick={handleCancel}>No, Cancel</Button>
          {itemType === 'category'|| itemType === 'subscription' ||itemType === 'promo'?  <Button variant='danger' onClick={handleDelete}>Yes, {item?.status === 'inactive' ? 'Activate' : 'Deactivate'} </Button> :   itemType === 'voucher'?  <Button variant='danger' onClick={handleDelete}>Yes, {item?.isActive === false ? 'Activate' : 'Deactivate'}</Button> :
          itemType === 'product' ||  itemType === 'customer-account' || itemType === 'voucherTemplate' || itemType === 'boxTemplate' || itemType === 'order'  || actionType === 'delete' ?
          <Button variant='danger' onClick={handleDelete}>Yes, Delete</Button> : <Button variant='danger' onClick={handleDelete}>Yes, Deactivate</Button>}
         
          </div>
         

        </Modal.Body>
        
          </Modal>
)
}

export default DeleteProductModal
