
import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate, useParams} from 'react-router-dom'
import { Form, Button, Image, Card, Overlay, Popover, Row, Col,ListGroup, Dropdown, DropdownDivider } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import deleteIcon from '../../assets/delete.svg'
import { FaTrash} from 'react-icons/fa';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import orderRoute from '../../services/orderRoute';
import { debounce } from 'lodash';
import search from '../../assets/search.svg'
import { getParsedLocalStorageItem } from './AdminProfile';
import productRoute from '../../services/productRoute';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const OrderModification = () => {
    const { showToast } = useAppContext();
    const { orderId } = useParams();
    const navigate = useNavigate();
   
  const [orderDetail, setOrderDetail] = useState({});
  const [initialOrderDetail, setInitialOrderDetail] = useState({});
  const [globalProducts, setGlobalProducts] = useState([]);
  const [globalSubscriptions, setGlobalSubscriptions] = useState([]);
  const [searchProduct, setSearchProduct] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newlyAddedItems, setNewlyAddedItems] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [initialQuantities, setInitialQuantities] = useState({});
  const [orderLoading, setOrderLoading] = useState(false);
     const [error, setError] = useState("");
   
      const [recipientName, setRecipientName]= useState(orderDetail?.recipientName || '')
  const targetRef = useRef(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const targetBoxRef = useRef(null);
  const [isBoxPopoverVisible, setIsBoxPopoverVisible] = useState(false);
  const { getGlobalProducts } = productRoute();
  const [totalAmount, setTotalAmount] = useState(0);
  const [hasModifications, setHasModifications] = useState(false);
  const userData = getParsedLocalStorageItem('userData')
 const {getGlobalBoxes}= productRoute()
    
    const {getAdminSingleOrder,orderModify,getDeliveryLGAs,getDeliveryStates}= orderRoute();
      const [lgaOptions, setLgaOptions] = useState([]);
        const [originalLgaOptions, setOriginalLgaOptions] = useState([]);
      
        const [deliveryStates, setDeliveryStates] = useState([]);
        
      
        useEffect(()=>{
          getDeliveryStates(
            showToast, setDeliveryStates 
          )
        },[])
        useEffect(() => {
          getAdminSingleOrder(orderId, setOrderLoading, showToast, (order) => {
            setOrderDetail(order);
            setInitialOrderDetail(order); // Store the initial order for comparison
        
            const quantities = {};
            order?.cart?.forEach(item => {
              quantities[item?.productId?.id || item?.box?.id || item?.productId?._id] = item.quantity;
            });
            setInitialQuantities(quantities);
          });
        }, []);
        

    // useEffect(()=> {
    //   getAdminSingleOrder (
    //     orderId, setOrderLoading,showToast,  (order) => {
    //         setOrderDetail(order);
    //         const quantities = {};
    //         order?.cart.forEach(item => {
    //           quantities[item?.productId?.id || item?.box?.id || item?.productId?._id] = item.quantity;
    //         });
    //         setInitialQuantities(quantities);
    //       });
      
    // },[])
  
    useEffect(()=>{
      getGlobalBoxes(
        setLoading, showToast, setGlobalSubscriptions
      
      )  },[])
    useEffect(() => {
        // Define a debounced version of the getProducts function
        const debouncedGetProducts = debounce((searchProduct) => {
          getGlobalProducts(
            setLoading,
            showToast,
            setGlobalProducts,
            searchProduct,
        );
        }, 500);
    
        if (searchProduct !== '') {
          setSearchPerformed(true)
            debouncedGetProducts(searchProduct);
        } else {
          
            getGlobalProducts(
                setLoading,
                showToast,
                setGlobalProducts,
            );
        }
    
        // Cleanup function to cancel the debounced function
        return () => {
            debouncedGetProducts.cancel();
        };
    }, [searchProduct]);


     useEffect(() => {
     
            if (orderDetail?.address?.state) {
              const selectedState = deliveryStates.find(state => state.name === orderDetail?.address?.state?.name);
              if (selectedState) {
                getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
                
              }
            }
          }, [orderDetail?.address?.state, deliveryStates]);
    
    // useEffect(() => {
      
    //   let total = 0;
    
    //   // Calculate total from newlyAddedItems
    //   newlyAddedItems.forEach(item => {
    //     total += item.quantity * (item?.productId?.price || item?.productId?.amount);
    //   });
    
    //   // Calculate total from orderDetail.cart
    //   orderDetail?.cart?.forEach(item => {
    //     const initialQuantity = initialQuantities[item?.productId?.id || item?.box?.id || item?.productId?._id ];
    //     if (item?.quantity > initialQuantity) {
    //       total += (item?.quantity - initialQuantity) * (item?.productId?.price || item?.box?.amount); // Use item.productId.price instead of item.price
    //     }
    //   });
    
    //   setTotalAmount(total);
    //   const hasChanges = newlyAddedItems.length > 0 || orderDetail?.cart?.some(item => item?.quantity > initialQuantities[item?.productId?.id || item?.box?.id || item?.productId?._id]);
    //   setHasModifications(hasChanges);
    // }, [newlyAddedItems, orderDetail?.cart, initialQuantities]);
    
    useEffect(() => {
      let total = 0;
    
      // Calculate total from newlyAddedItems
      newlyAddedItems.forEach(item => {
        total += item.quantity * (item?.productId?.price || item?.productId?.amount);
      });
    
      // Calculate total from orderDetail.cart
      orderDetail?.cart?.forEach(item => {
        const initialQuantity = initialQuantities[item?.productId?.id || item?.box?.id || item?.productId?._id];
        if (item?.quantity > initialQuantity) {
          total += (item?.quantity - initialQuantity) * (item?.productId?.price || item?.box?.amount);
        }
      });
    
      setTotalAmount(total);
    
      // Check if the cart has changes
      const cartChanged = newlyAddedItems.length > 0 || 
        orderDetail?.cart?.some(item => item?.quantity > initialQuantities[item?.productId?.id || item?.box?.id || item?.productId?._id]);
    
      // Check if other order details have changed
      const deliveryTypeChanged = orderDetail?.deliveryType !== initialOrderDetail?.deliveryType;
      const recipientChanged = initialOrderDetail?.recipientName && recipientName !== initialOrderDetail?.recipientName;
      const orderCommentChanged = orderDetail?.orderComment !== initialOrderDetail?.orderComment;
      const phoneNumberChanged = orderDetail?.phoneNumber !== initialOrderDetail?.phoneNumber;
    
      // Check if delivery date has changed (convert to ISO format for comparison)
      const isValidDate = (date) => date && !isNaN(new Date(date).getTime());

const deliveryDateChanged = isValidDate(orderDetail?.deliveryDate) &&
                            isValidDate(initialOrderDetail?.deliveryDate) &&
                            new Date(orderDetail.deliveryDate).toISOString() !== new Date(initialOrderDetail.deliveryDate).toISOString();

    
      // Check if address has changed
      const addressChanged = (
        orderDetail?.address?.streetAddress !== initialOrderDetail?.address?.streetAddress ||
        orderDetail?.address?.city?.name !== initialOrderDetail?.address?.city?.name ||
        orderDetail?.address?.state?.name !== initialOrderDetail?.address?.state?.name ||
        orderDetail?.address?.landmark !== initialOrderDetail?.address?.landmark
      );
    
      // Set hasModifications to true if any change is detected
      const hasChanges = cartChanged || deliveryTypeChanged || recipientChanged || orderCommentChanged || phoneNumberChanged || deliveryDateChanged || addressChanged;
    
      setHasModifications(hasChanges);
    }, [newlyAddedItems, orderDetail, initialQuantities, recipientName, initialOrderDetail]);
    
      
      const togglePopover = () => {
        setIsPopoverVisible(prev => !prev);
      };

      const hideBoxPopover = () => setIsBoxPopoverVisible(false);
      const toggleBoxPopover = () => {
        setIsBoxPopoverVisible(prev => !prev);
      };
      const isNigerianPhoneNumber = (phoneNumber) => {
        // This regex matches phone numbers starting with '070', '080', '090', '081', and '091'
        return /^0[7-9][0-1]\d{8}$/.test(phoneNumber) || /^091\d{8}$/.test(phoneNumber);
      };
     
      const hidePopover = () => setIsPopoverVisible(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
      
        if (name === "state") {
          const selectedState = deliveryStates.find(state => state.name === value);
          if (selectedState) {
            getDeliveryLGAs(selectedState.id, showToast, setLgaOptions, setOriginalLgaOptions);
          }
      
      
          setOrderDetail(prev => ({
            ...prev,
            address: { ...prev.address, state: selectedState }
          }));
          setOrderDetail(prevDetails => ({
            ...prevDetails,
            address: {
              ...prevDetails.address,
              streetAddress: ''
            }
          }));
        } else if (name === "streetAddress") {
         
          setOrderDetail(prev => ({
            ...prev,
            address: { ...prev.address, [name]: value }
          }));
        } else {
          // Handles all other fields outside of the 'address' object and special cases
          setOrderDetail(prev => ({ ...prev, [name]: value }));
        }
      };
        
      const formatOptionLabel = ({ label, value }) => {
        if (value === "comingSoon") {
          // Split the label to insert styling on a specific part
          const firstPart = "We are coming to ";
          const secondPart = label.split('soon! ')[0].replace(firstPart, '');
          const thirdPart = "soon! ";
          const actionPart = "Join our waitlist and be the first to know when we do.";
         
      
          return (
            <div>
              {firstPart}
              <span>{secondPart}</span>
              {thirdPart}
              <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
            
            </div>
          );
        }
        // Return the label as is for other options
        return label;
      };
      
      const handleInputChange = (inputValue, actionMeta) => {
        if (actionMeta.action === "input-change") {
          const filteredLgas = originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
          if (filteredLgas.length === 0 && inputValue.trim() !== "") {
            setLgaOptions([{ value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }]);
          } else {
            setLgaOptions(filteredLgas);
          }
        }
      };
      
      
      
      
      const handleSelectChange = (selectedOption) => {
        if (!selectedOption) {
        
          setOrderDetail((prevDetails) => ({
            ...prevDetails,
            address:{ ...prevDetails.address,city: {...prevDetails.address.city, name:''}} 
          }));
          setLgaOptions(originalLgaOptions)
        } else if (selectedOption.value === "comingSoon") {
        
          setOrderDetail((prevDetails) => ({
            ...prevDetails,
            address:{ ...prevDetails.address,city: {...prevDetails.address.city, name:''}}
          }));
          
          const selectedState = deliveryStates.find(state => state.name === orderDetail?.address?.state?.name);
          if (selectedState) {
            getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
          }
         
        } else {
         
          setOrderDetail((prevDetails) => ({
            ...prevDetails,
            address:{ ...prevDetails.address, city: {...prevDetails.address.city, name:selectedOption.value}}
          }));
        }
      };
      const isProductChecked = (productId) => {
        return newlyAddedItems.some(item => (item?.productId?.id || item?.box?.id || item?.productId?._id) === productId) ||
               orderDetail.cart?.some(item => (item?.productId?.id || item?.box?.id || item?.productId?._id) === productId);
      };
    
      const getProductQuantity = (productId) => {
        const product = newlyAddedItems?.find(item => (item?.productId?.id || item?.box?.id || item?.productId?._id) === productId) ||
                        orderDetail?.cart?.find(item => (item?.productId?.id || item?.box?.id || item?.productId?._id) === productId);
        return product ? product.quantity : 0;
      };
    
      const handleToggleItem = (productId) => {
        const product = globalProducts.find(p => (p.id || p?._id)  === productId)|| globalSubscriptions.find(p => (p.id || p?._id) === productId);
        if (!product) {
          showToast({ toastText: 'Product not found.', isSuccess: false });
          return;
        }
    
        if (product.stockLevel === 'out_of_stock' || product.quantityAvailable === 0) {
          showToast({ toastText: 'This product is out of stock.', isSuccess: false });
          return;
        }
    
        const existingItemIndex = orderDetail.cart?.findIndex(item => (item?.productId?.id || item?.productId?._id || item?.box?.id) === productId);
        const newItemIndex = newlyAddedItems.findIndex(item => (item?.productId?.id || item?.box?.id || item?.productId?._id) === productId);
    
        if (newItemIndex !== -1) {
          const updatedNewItems = [...newlyAddedItems];
          updatedNewItems.splice(newItemIndex, 1);
          setNewlyAddedItems(updatedNewItems);
         
        } else if (existingItemIndex !== -1) {
          // const updatedOrderCart = [...orderDetail.cart];
          // updatedOrderCart.splice(existingItemIndex, 1);
          // setOrderDetail(prevDetail => ({ ...prevDetail, cart: updatedOrderCart }));
          showToast({ toastText: 'This item is already in your order and cannot be removed.', isSuccess: false });
  
        } else {
          const newItem = {
            productId: product,
            quantity: 1,
            price: product.price || product?.amount
          };
          setNewlyAddedItems([...newlyAddedItems, newItem]);
          
        }
      };
    
      const handleChangeQuantity = (productId, delta) => {
        const product =  globalProducts.find(p => (p.id || p?._id)  === productId) || globalSubscriptions.find(p => (p.id || p?._id)  === productId);
        if (!product) {
          showToast({ toastText: 'Product not found.', isSuccess: false });
          return;
        }
    
     
    const existingItemIndex = orderDetail.cart?.findIndex(item => (item?.productId?.id || item?.box?.id || item?.productId?._id) === productId);
    const newItemIndex = newlyAddedItems.findIndex(item => (item?.productId?.id || item?.box?.id || item?.productId?._id) === productId);
    
        if (existingItemIndex !== -1) {
          const item = orderDetail?.cart[existingItemIndex];
          const initialQuantity = initialQuantities[(item?.productId?.id || item?.box?.id || item?.productId?._id)];
          const newQuantity = item.quantity + delta;
    
          if (newQuantity < initialQuantity) {
            showToast({ toastText: 'Modified quantity cannot be less than initial quantity.', isSuccess: false });
            return;
          }
    
          if (newQuantity > product.quantityAvailable) {
            if( product.quantityAvailable === 0){
              showToast({ toastText: 'This product is out of stock.', isSuccess: false });
            }else{
            showToast({ toastText: `We only have ${product.quantityAvailable} items in stock.`, isSuccess: false });}
            return;
          }
    
          const updatedOrderCart = [...orderDetail.cart];
          updatedOrderCart[existingItemIndex] = { ...item, quantity: newQuantity };
          setOrderDetail(prevDetail => ({ ...prevDetail, cart: updatedOrderCart }));
        
       
        } else if (newItemIndex !== -1) {
          const item = newlyAddedItems[newItemIndex];
      const newQuantity = item.quantity + delta;

      if (newQuantity <= 0) {
        const updatedNewItems = [...newlyAddedItems];
        updatedNewItems.splice(newItemIndex, 1);
        setNewlyAddedItems(updatedNewItems);
       
           
      
            return;
          }
    
          if (newQuantity > product.quantityAvailable) {
            if( product.quantityAvailable === 0){
              showToast({ toastText: 'This product is out of stock.', isSuccess: false });
            }else{
            showToast({ toastText: `We only have ${product.quantityAvailable} items in stock.`, isSuccess: false });}
            return;
          }
    
          const updatedNewItems = [...newlyAddedItems];
          updatedNewItems[newItemIndex] = { ...item, quantity: newQuantity };
          setNewlyAddedItems(updatedNewItems);
          
        }
      };

//       const handlePay = () => {

//         const getItemDetails = (item) => {
//           if (item?.productId?.productName) {
//             return {
//               id: item?.productId?.id,
//               name: item?.productId?.productName,
//               type: 'product'
//             };
//           } else if (item?.box?.name || item?.productId?.name) {
//             return {
//               id: item?.box?.id || item?.productId?._id || item?.productId?.id, 
//               name: item?.box?.name || item?.productId?.name,
//               type: 'box'
//             };
//           }
//           return null;
//         };
        

//  // Generating modifiedCart by filtering and mapping items based on quantity
//  const modifiedCart = orderDetail?.cart
//  ?.filter(item => {
//    const itemDetails = getItemDetails(item);
//    return itemDetails && item?.quantity > initialQuantities[itemDetails?.id];
//  })
//  ?.map(item => {
//    const itemDetails = getItemDetails(item);
//    return {
//      [itemDetails?.type === 'product' ? 'productId' : 'box']: itemDetails?.id,
//      quantity: item?.quantity - initialQuantities[itemDetails?.id],
//      // productName: itemDetails?.name
//    };
//  });

// // Generating newItems array
// const newItems = newlyAddedItems?.map(item => {
//  const itemDetails = getItemDetails(item);
//  return {
//    [itemDetails?.type === 'product' ? 'productId' : 'box']: itemDetails?.id,
//    quantity: item?.quantity,
//    // productName: itemDetails?.name
//  };
// });



// const finalCart = [...modifiedCart, ...newItems];
      
//         // Prepare the body object to pass to orderModify function
//         const body = {
//           action: "edit",
//           newOrder: {
//             deliveryDate: orderDetail.deliveryDate ? new Date(orderDetail.deliveryDate).toISOString() : null,

//             phoneNumber: orderDetail?.phoneNumber,
//             deliveryType: orderDetail.deliveryType,
//           }
//         };
//         const address = {
//           streetAddress: orderDetail?.address?.streetAddress,
//           city: orderDetail?.address?.city?.name,
//           state: orderDetail?.address?.state?.name,
//           landmark: orderDetail?.address?.landmark,
//       };
    
//       const selectedState = deliveryStates.find(state => state.name === orderDetail?.address?.state?.name);
//       const stateId = selectedState ? selectedState.id : null;
    
//       // Extract LGA ID
//       const selectedLGA = lgaOptions.find(option => option.label === orderDetail?.address?.city?.name);
//       const lgaId = selectedLGA ? selectedLGA.id : null;
    
      
//       if (finalCart.length > 0) {
//         body.newOrder.cart = finalCart;
//       }
    
//       if (orderDetail?.orderComment !== '') {
//         body.newOrder.orderComment = orderDetail?.orderComment;
//     }
    
    
//     if (recipientName !== '') {
//        body.newOrder.recipientName = recipientName;
//     }
    
      
//       if (orderDetail?.deliveryType === 'delivery') {
//          body.newOrder.address = {
//           ...address,
//           state: stateId, // Pass state ID instead of state name
//           city: lgaId, // Pass LGA ID instead of LGA name
//         };
//       }
//         console.log(body)
//         orderModify(orderId, body, setLoading, showToast);
//       };
      
    
    
const handlePay = () => {
  const getItemDetails = (item) => {
    if (item?.productId?.productName) {
      return {
        id: item?.productId?.id,
        name: item?.productId?.productName,
        type: 'product'
      };
    } else if (item?.box?.name || item?.productId?.name) {
      return {
        id: item?.box?.id || item?.productId?._id || item?.productId?.id, 
        name: item?.box?.name || item?.productId?.name,
        type: 'box'
      };
    }
    return null;
  };

  // Generating modifiedCart by filtering and mapping items based on quantity
  const modifiedCart = orderDetail?.cart
    ?.filter(item => {
      const itemDetails = getItemDetails(item);
      return itemDetails && item?.quantity > initialQuantities[itemDetails?.id];
    })
    ?.map(item => {
      const itemDetails = getItemDetails(item);
      return {
        [itemDetails?.type === 'product' ? 'productId' : 'box']: itemDetails?.id,
        quantity: item?.quantity - initialQuantities[itemDetails?.id],
      };
    });

  // Generating newItems array
  const newItems = newlyAddedItems?.map(item => {
    const itemDetails = getItemDetails(item);
    return {
      [itemDetails?.type === 'product' ? 'productId' : 'box']: itemDetails?.id,
      quantity: item?.quantity,
    };
  });

  const finalCart = [...modifiedCart, ...newItems];

  // Prepare the body object with only changed values
  const body = { action: "edit", newOrder: {
    paymentMethod: 'Direct Transfer',
  } };

  if (finalCart.length > 0) {
    body.newOrder.cart = finalCart;
  }

  if (orderDetail?.orderComment !== initialOrderDetail?.orderComment) {
    body.newOrder.orderComment = orderDetail?.orderComment;
  }

  if (recipientName && recipientName !== initialOrderDetail?.recipientName) {
    body.newOrder.recipientName = recipientName;
  }

  if (orderDetail?.phoneNumber !== initialOrderDetail?.phoneNumber) {
    body.newOrder.phoneNumber = orderDetail?.phoneNumber;
  }

  if (orderDetail?.deliveryType !== initialOrderDetail?.deliveryType) {
    body.newOrder.deliveryType = orderDetail?.deliveryType;
  }

  const isValidDate = (date) => date && !isNaN(new Date(date).getTime());

  if (
    isValidDate(orderDetail?.deliveryDate) &&
    isValidDate(initialOrderDetail?.deliveryDate) &&
    new Date(orderDetail.deliveryDate).toISOString() !==
      new Date(initialOrderDetail.deliveryDate).toISOString()
  ) {
    body.newOrder.deliveryDate = new Date(orderDetail.deliveryDate).toISOString();
  }

  if (orderDetail?.deliveryType === "delivery") {
    const address = {
      streetAddress: orderDetail?.address?.streetAddress,
      city: orderDetail?.address?.city?.name,
      state: orderDetail?.address?.state?.name,
      landmark: orderDetail?.address?.landmark,
    };

    const selectedState = deliveryStates.find(
      (state) => state.name === orderDetail?.address?.state?.name
    );
    const stateId = selectedState ? selectedState.id : null;

    const selectedLGA = lgaOptions.find(
      (option) => option.label === orderDetail?.address?.city?.name
    );
    const lgaId = selectedLGA ? selectedLGA.id : null;

    const addressChanged =
      address.streetAddress !== initialOrderDetail?.address?.streetAddress ||
      address.city !== initialOrderDetail?.address?.city?.name ||
      address.state !== initialOrderDetail?.address?.state?.name ||
      address.landmark !== initialOrderDetail?.address?.landmark;

    if (addressChanged) {
      body.newOrder.address = {
        ...address,
        state: stateId,
        city: lgaId,
      };
    }
  }

  
  orderModify(orderId, body, setLoading, showToast);
};


     
 
  const handleBack = () => {
   
    navigate(-1);
  }

  return (
    <div>
        <div className='d-flex  justify-content-between align items-center header-admin  py-4 pe-5 mb-4 ps-4'>
        <div className='d-flex gap-3 align-items-center'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow' />
        <h3 style={{color:'#344054'}} className='my-auto'>Modify Order</h3>
        </div>
      
        </div>
        <div className=' pe-5 ps-4 '>
        {orderLoading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):    <div>
 
           <Card style={{backgroundColor: '#FCFCFC',border:'none'}} className='py-3 px-3 mb-5 h-100'>
                      <Form >
                      <Row className=''>
                          <Col xs={4} className='pe-3 mb-4'>
                          <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Recipient Name (if different from sender):</p>
                          <Form.Control type='text' name='productName' value={recipientName || orderDetail?.recipientName} onChange={(e)=> setRecipientName(e.target.value)}/>
                          </Col>
                          <Col xs={4} className='px-2 mb-4'>
              <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Phone Number<sup className='text-danger'>*</sup></p>
              <Form.Control
                name='phoneNumber'
                type='text'
                placeholder='Enter phone number'
                value={orderDetail?.phoneNumber}
                onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  if (numericValue.length <= 11) {
                    setOrderDetail({ ...orderDetail, phoneNumber: numericValue });
                  }
              
                  
                  if (numericValue.length === 11 && !isNigerianPhoneNumber(numericValue)) {
                    setError({...error, phoneNumber: 'Please enter a valid 11-digit Nigerian phone number.'});
                  } else {
                  
                    setError({...error, phoneNumber: ''});
                  }
                }}
              />
              {error.phoneNumber && <div className="text-danger" style={{fontSize:'12px'}}>{error.phoneNumber}</div>}
            </Col>
            <Col xs={4} className='px-2 mb-4'>
            
            <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Delivery Comment (optional)</p>
                   
                      <Form.Control
            type='text' placeholder='Add notes/comments to your order(s)' rows={3} className=' p-2 w-100' style={{border:'1px solid #D7D7D7'}}value={orderDetail?.orderComment} onChange={handleChange}
            
          />
          
          
          
                      </Col>
                       <Col xs={4} className='px-2 mb-5'>
                                      <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Delivery Type <sup className='text-danger'>*</sup></p>
                                      <Form.Select
                              className="custom-select"
                              onChange={handleChange}
                              value={orderDetail?.deliveryType}
                              name="deliveryType"
                              required
                            > <option value="" disabled>Select delivery type</option>
                          
                              <option value='pickup'>In-store pickup</option>
                              <option value='delivery'>Home delivery</option>
                      
                            </Form.Select>
                                      
                                      </Col>
          
                      {orderDetail?.deliveryType === 'delivery' && <>
                        
                         
                          <Col xs={4} className='px-2 mb-4'>
                          <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>State <sup className='text-danger'>*</sup></p>
                          <Form.Select
            className="custom-select"
            onChange={handleChange}
            name="state"
            value={orderDetail?.address?.state?.name || ''}
            required
          
          >
            <option value="" disabled>Select a state</option>
            {deliveryStates.map((state,index) => (
                  <option value={state?.name} key={index}>{state?.name}</option>
              ))}
          </Form.Select>
                          
                          </Col>
                          <Col xs={4} className='px-2 mb-4'>
                          <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>LGA<sup className='text-danger'>*</sup> (Please select a state first)</p>
          
                       <Select
                   formatOptionLabel={formatOptionLabel}
                   options={lgaOptions}
                   onInputChange={handleInputChange}
                   onChange={handleSelectChange}
                  required
                 
                    value={lgaOptions.find(option => option.value === orderDetail?.address?.city?.name || '')}
          
                   name="LGA"
                  
                   isClearable={true}
                   styles={{
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: '150px',
                      overflowY: 'auto'
                    })
                  }}
                 />
                          </Col>
                          <Col xs={4} className='px-2 mb-4'>
                          <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Street Address <sup className='text-danger'>*</sup></p>
                          
                <Form.Control
                  type="text"
                  placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
                  onChange={handleChange}
                  name="streetAddress"
                  value={orderDetail?.address?.streetAddress} required
                />
           
                          </Col>
                          <Col xs={4} className='px-2 mb-4'>
                          <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Landmark<sup className='text-danger'>*</sup></p>
                          <Form.Control type='text' name='landmark' value={orderDetail?.address?.landmark} required  onChange={handleChange}/>
                          </Col></>}  
                          <Col xs={4} className='px-2 mb-4'>
                          <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Delivery Date<sup className='text-danger'>*</sup></p>
                          <div>
                          <DatePicker
                    selected={orderDetail?.deliveryDate}
                    onChange={(date) => 
                      setOrderDetail({...orderDetail, deliveryDate:date})
                    }
                  
                    dateFormat="MMMM d, yyyy" // Ensures the long format
                    placeholderText="Select a date"
                    className="form-control"
                    showPopperArrow={false} 
                    required// Optional: Hide the popper arrow
                  />
                  
                  </div>
                </Col>
                         
          </Row>
                         
          
          
                             
                  {/* <div className='my-3 d-flex gap-2 justify-content-end'>
                  <Button className='px-5 py-2' variant='danger' onClick={()=> setIsEditing(false)}>Cancel</Button>
                    <Button className='px-5 py-2' style={{backgroundColor: '#469B12', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
          }} type='submit'>Save</Button>
                  </div> */}
                
          
                  </Form>
                  </Card>
          <Card style={{backgroundColor: '#469B1205',border:'none'}} className='py-3 h-100'>
            <div className='d-flex justify-content-between px-4 mt-1 mb-3 '>
                <h5 style={{color:'#344054', fontWeight:'600'}}>Item Summary</h5>
              
              </div>
          <Row style={{borderBottom:'1px solid #E1E6EF'}} className='px-2 mb-2 mx-2 ' >
          <Col xs={2}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Image</h6>
          </Col>
          <Col xs={4}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Product Name</h6>
          </Col>
         
          <Col xs={1}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>QTY</h6>
          </Col>
        
          <Col xs={2}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Price </h6>
          </Col>
        </Row>
        <div className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.cart?.map((item)=> (
        <Row  key={item?._id} className='mx-2 pb-1 mt-0 pt-0' style={{borderBottom:'1px solid #E1E6EF'}}>
           <Col xs={2} className='mt-0 pt-0'>
          <img 
        src={item?.productId?.productImage || item?.box?.boxImage} 
        alt={item?.productId?.productName || item?.box?.name} 
        style={{ width: "70px", height: "70px" }} 
      />
          </Col>
         
          <Col xs={4}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>{item?.productId?.productName || item?.box?.name}</h6>
          
          </Col>
         
          <Col xs={1}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4 ms-2'>{initialQuantities[item?.productId?.id || item?.box?.id || item?.productId?._id]}</h6>
          </Col>
         
          <Col xs={2}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>
  {item?.productId?.price?.toLocaleString() || item?.box?.amount?.toLocaleString()}
</h6>

          </Col>

          <Col xs={4} md={3} className='d-flex gap-3 align-items-center'>
                            <div className="btn-group btn-group-sm gap-1" >
      <Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item?.productId?.id || item?.box?.id || item?.productId?._id, -1)}>-</Button>
      <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'20px', height:'30px', fontWeight:'600'}}>{item.quantity}</Button>
      <Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item?.productId?.id || item?.box?.id || item?.productId?._id, 1)}>+</Button>
      
      </div>
      
                            </Col>
        </Row>
        ))}

{newlyAddedItems.map((item) => (
   <Row  key={item?.id || item?._id} className='mx-2 pb-1 mt-0 pt-0' style={{borderBottom:'1px solid #E1E6EF'}}>
   <Col xs={2} className='mt-0 pt-0'>
  <img 
src={item?.productId?.productImage || item?.productId?.boxImage} 
alt={item?.productId?.productName || item?.productId?.name} 
style={{ width: "70px", height: "70px" }} 
/>
  </Col>
 
  <Col xs={4}>
  <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>{item?.productId?.productName || item?.productId?.name}</h6>
  </Col>
 
  <Col xs={1}>
  <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4 ms-2'>{item?.quantity}</h6>
  </Col>
 
  <Col xs={2}>
  <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>
{item?.productId?.price?.toLocaleString() || item?.productId?.amount?.toLocaleString()}
</h6>

  </Col>

  <Col xs={4} md={3} className='d-flex gap-3 align-items-center'>
  <div className="btn-group btn-group-sm gap-1" >
<Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id || item?.productId?._id, -1)}>-</Button>
<Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'20px', height:'30px', fontWeight:'600'}}>{item.quantity}</Button>
<Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id || item?.productId?._id, 1)}>+</Button>

</div>
<img src={deleteIcon} alt='trash' onClick={() => handleToggleItem(item.productId.id || item?.productId?._id)} style={{cursor:'pointer'}}/>

                    </Col>
</Row>
))}
        </div>
      
        </Card>
        <div className='my-3'>
        <div className='d-flex gap-3 gap-md-4'>
                  <p style={{fontWeight:'500'}}>Add More Items:</p>
                  <p style={{fontWeight:'500',color:'#469B12', textDecoration:'underline', cursor:'pointer'}} onClick={togglePopover} ref={targetRef} >+ Add Products</p>
                  <p style={{fontWeight:'500',color:'#469B12', textDecoration:'underline', cursor:'pointer'}}  onClick={toggleBoxPopover} ref={targetBoxRef}>+ Add Virtue Boxes</p>
                  </div>

                  <Col xs={12} md={7}>
                  <div style={{ cursor: 'pointer', border: '1px solid #ced4da', borderRadius: '8px', padding: '0.35rem 0.75rem', minHeight: '38px', gap:'5px',  }} className='d-flex align-items-center justify-content-between'>
                
   <div className='item-container'>
        {newlyAddedItems?.length > 0 ? (
          newlyAddedItems?.map((item) => (
            <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500',fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} key={item?.productId?.id || item?.productId?._id} onClick={(e) => {
              e.stopPropagation(); // Prevent popover from toggling
              handleToggleItem(item.productId.id || item?.productId?._id);
            }}>
              {item?.productId?.productName || item?.productId?.name} ({item.quantity}) &nbsp; X
            </Button>
          ))
        ) : (
          <span className="text-muted">Select additional items to add to your order.</span>
        )}
        </div>
        <div className='d-flex justify-content-end'>
        <i className="bi bi-chevron-down"></i>
        </div>
                  </div>
                  </Col>
                  <Overlay
    show={isPopoverVisible}
    target={targetRef.current}
    placement="bottom"
    rootClose={true}
    onHide={hidePopover}
  >
 
                    <Popover className='custom-popover' style={{ width: '430px' }}>
                      <Popover.Body>
                        <div className='d-flex align-items-center position-relative py-1 px-1 mb-3'>
                          {searchProduct !== '' && (
                            <i className='bi bi-x' style={{ top: '5%', right: '1.5%', position: 'absolute', fontSize: '1.5rem', color: '#98A2B3' }} onClick={() => setSearchProduct('')}></i>
                          )}
                          <Form.Control
                            style={{ border: '1px solid #E1E6EF', backgroundColor: '#E1E6EF', borderRadius: '3px', fontWeight: '500' }}
                            placeholder='Search by product name'
                            value={searchProduct}
                            onChange={(e) => setSearchProduct(e.target.value)}
                            autoFocus
                          />
                        </div>
                        {loading ? (
                          <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                            <div className="spinner-border text-success" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          globalProducts.length > 0 ? (
                            <>
                              <div style={{ borderTop: '1px solid #E1E6EF' }} className='pb-2 pt-3'>
                                <h5 className='' style={{ fontWeight: '500' }}>Items</h5>
                              </div>
                              <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border: 'none' }} className='no-border-listgroup'>
                                {globalProducts.map((product) => (
                                  <ListGroup.Item key={product.id} action className='d-flex justify-content-between'>
                                    <Form.Check
                                      type="checkbox"
                                      label={`${product.productName} - ₦${product.price?.toLocaleString()}`}
                                      checked={isProductChecked(product.id)}
                                    onChange={() => handleToggleItem(product.id)}
                                    />
                                    {isProductChecked(product?.id) && (
                                      <div className="btn-group btn-group-sm">
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }} onClick={() => handleChangeQuantity(product.id, -1)}>-</Button>
                                        <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}>{getProductQuantity(product.id)}</Button>
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}  onClick={() => handleChangeQuantity(product.id, 1)}>+</Button>
                                      </div>
                                    )}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>
                          ) : (
                            searchPerformed && globalProducts.length === 0 ? (
                              <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                <h4 className='mb-2'>No products found</h4>
                                <Button onClick={() => setSearchProduct('')}>View all products</Button>
                              </div>
                            ) : (
                              <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                <h4>You have no products</h4>
                              </div>
                            )
                          )
                        )}
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                  <Overlay
    show={isBoxPopoverVisible}
    target={targetBoxRef.current}
    placement="bottom"
    rootClose={true}
    onHide={hideBoxPopover}
  >
 
                    <Popover className='custom-popover' style={{ width: '430px' }}>
                      <Popover.Body>
                      {loading ? (
                          <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                            <div className="spinner-border text-success" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          globalSubscriptions?.length > 0 ? (
                            <>
                              <div style={{ borderTop: '1px solid #E1E6EF' }} className='pb-2 pt-3'>
                                <h5 className='' style={{ fontWeight: '500' }}>Subscription Boxes</h5>
                              </div>
                              <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border: 'none' }} className='no-border-listgroup'>
                                {globalSubscriptions.map((box) => (
                                  <ListGroup.Item key={box?.id || box?._id} action className='d-flex justify-content-between'>
                                    <Form.Check
                                      type="checkbox"
                                      label={`${box?.name} - ₦${box?.amount?.toLocaleString()}`}
                                      checked={isProductChecked(box?.id || box?._id)}
                                    onChange={() => handleToggleItem(box?.id || box?._id)}
                                    />
                                    {isProductChecked(box?.id || box?._id) && (
                                      <div className="btn-group btn-group-sm">
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }} onClick={() => handleChangeQuantity(box?.id || box?._id, -1)}>-</Button>
                                        <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}>{getProductQuantity(box?.id || box?._id)}</Button>
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}  onClick={() => handleChangeQuantity(box?.id || box?._id, 1)}>+</Button>
                                      </div>
                                    )}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>
                          ) : (
                          
                             
                              <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                <h4>You have no boxes</h4>
                              </div>
                            
                          )
                        )}
                       
                      
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                  </div>
                  <div className=' me-lg-5'>
                    <div className='d-flex gap-4 justify-content-end'>
                  <h5 style={{color:'#4F4F4F', fontWeight:'600'}}>Total:</h5>
            <h5 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</h5>
                  </div>
                  <div className='d-flex gap-5 justify-content-end my-3'>
                <Button className=''onClick={handlePay} disabled={!hasModifications} style={{backgroundColor: !hasModifications ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40', width:'200px'
}}>Save</Button>
                </div> 
                </div> 
        </div>
}
</div>
    </div>
  )
}

export default OrderModification


