import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate, useParams} from 'react-router-dom'
import { Form, Button, Image, Card, Overlay, Popover, Row, Col,ListGroup, Dropdown, DropdownDivider, Table} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import edit from '../../assets/edit.svg'
import deleteIcon from '../../assets/delete.svg'
import { FaTrash} from 'react-icons/fa';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import orderRoute from '../../services/orderRoute';
import { debounce } from 'lodash';
import search from '../../assets/search.svg'
import adminRoute from '../../services/adminRoute';
import { BsGear } from "react-icons/bs";
import user from '../../assets/profile-circled.svg'

const CustomerDetails = () => {
    const { showToast } = useAppContext();
    const { customerId } = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerDetail, setCustomerDetail] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: {
        streetAddress: '',
        LGA: '',
        state: '',
      },
      profilePicture:'',
      status:'',
      createdAt:'',
      orders:[],
    });
  
    const dropdownRef1 = useRef(null);
    
    const {getSingleAdminCustomers}= adminRoute();
 

    useEffect(()=> {
      getSingleAdminCustomers (
        customerId, setLoading,showToast, setCustomerDetail
      )
    },[])
  


    useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
       
        }
    
        function handleScroll() {
          setIsOpen(false);
        
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);


      const formatDateToLongForm = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      };
    
      
      const getStatusStyle = (status) => {
        switch (status) {
          case 'active':
            return { backgroundColor: 'white', color: '#115F04', border:'1px solid #115F04', padding:'4px 10px 4px 10px', borderRadius:'12px' };
          
          case 'pending':
            return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'4px 10px 4px 10px', borderRadius:'12px' };
          case 'inactive':
            
          return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'4px 10px 4px 10px',borderRadius:'12px' };
          default:
            return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #FEF1F2', padding:'4px 10px 4px 10px',borderRadius:'12px' };
        }
      };
      const capitalizeFirstLetter = (input) => {
        if (typeof input !== 'string') {
          return '';  // Return an empty string or some default text
        }
        return input.replace(/\b(\w)/g, s => s.toUpperCase());
      };

      const getStatusOrderStyle = (status) => {
        switch (status) {
          case 'order confirmed':
            return { backgroundColor: 'white', color: '#194298', border:'1px solid #194298', padding:'6px 16px 6px 16px', borderRadius:'16px' };
            case 'order delivered':
              return  { backgroundColor: 'white', color: '#08875D', border:'1px solid #08875D', padding:'6px 16px 6px 16px',borderRadius:'16px' };
              case 'order picked up':
                return  { backgroundColor: 'white', color: '#08875D', border:'1px solid #08875D', padding:'6px 16px 6px 16px',borderRadius:'16px' };
          case 'out for delivery':
            return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'16px' };
            case 'ready for pickup':
              return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'16px' };
          case 'order processing':
            return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px',borderRadius:'16px' };
          case 'awaiting payment':
            return { backgroundColor: 'white', color: '#FD7B1D', padding:'6px 16px 6px 16px',borderRadius:'16px',border:'1px solid #FD7B1D' };
          default:
            return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #FEF1F2', padding:'6px 16px 6px 16px',borderRadius:'16px' };
        }
      };
      
      const determineStatus = (order) => {
        let statusText = 'Status Unknown';
        let statusStyle = { backgroundColor: 'grey', color: 'white' };
      
        if (Array.isArray(order.orderInfo) && order.orderInfo.length > 0) {
          const lastStatusObj = order.orderInfo[order.orderInfo.length - 1]; // Get the last object
          statusText = capitalizeFirstLetter(lastStatusObj.orderStatus); // Assuming capitalizeFirstLetter function capitalizes each word correctly
        } 
        else if (order.paymentStatus === 'pending') {
          statusText = 'Awaiting Payment'; // This is already properly capitalized
        }
      
        statusStyle = getStatusOrderStyle(statusText.toLowerCase()); // Ensure status style is determined correctly
        return <span style={statusStyle}>{statusText}</span>;
      };

      
  const toggleDropdown = () => setIsOpen(!isOpen);
 
  const handleBack = () => {
   
    navigate(-1);
  }
  const truncateText = (text, maxLength = 12) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };




  return (
    <div>
        <div className='d-flex  justify-content-between align items-center header-admin  py-4 pe-5 mb-4 ps-4'>
        <div className='d-flex gap-3 align-items-center'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow' />
        <h3 style={{color:'#344054'}} className='my-auto'>Customer Details</h3>
        </div>
       <Button variant='outline-secondary' className='d-flex gap-2 align-items-center p-3' onClick={() => navigate(`/admin/customers/settings/${customerId}`)}  style={{fontWeight:'500', color:'#333333'}}><BsGear/> Account Settings</Button>
        </div>
        <div className=' pe-5 ps-4'>
        {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):    <div>
    <Row className='mb-5'>
      <Col xs={3} className='d-flex flex-column gap-2'>
      <Image roundedCircle src={customerDetail?.profilePicture ? customerDetail?.profilePicture : user} style={{width:'150px', height:'150px', objectFit:'cover'}}/>
      <h3  style={{color:'#000000', fontWeight:500}} className='mt-3'>{customerDetail?.firstName}&nbsp;{customerDetail?.lastName}</h3>
     
      <div className='d-flex justify-content-between align-items-center'>
        <h6 style={{color:'#333333', fontWeight:'500'}}>Status:</h6>
      <div style={getStatusStyle( customerDetail?.status?.toLowerCase() ||'unknown')} className='mb-2'>
  {customerDetail?.status ? capitalizeFirstLetter(customerDetail?.status) : 
    'Unknown'
  }
</div>
      </div>
          </Col>
          <Col className='px-3'>
  <Card style={{backgroundColor: '#469B1205',border:'none'}} className='p-3 h-100 '>
  <Row className=''>
                <Col xs={6} className=' mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>First Name:</p>
                <Form.Control type='text' value={customerDetail?.firstName}  disabled/>
                </Col>
              
                <Col xs={6} className=' mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Last Name:</p>
                <Form.Control type='text' value={customerDetail?.lastName}  disabled/>
                
                </Col>
                <Col xs={6} className=' mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Email Address:</p>
                <Form.Control type='text' value={customerDetail?.email}  disabled/>
                
                </Col>
                <Col xs={6} className=' mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Phone Number:</p>
                <Form.Control type='text' value={customerDetail?.phoneNumber}  disabled/>
                
                </Col>
                <Col xs={6} className=' mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Default  Address:</p>
                <Form.Control 
  type='text' 
  value={customerDetail?.address 
    ? `${customerDetail.address.streetAddress || ''}${customerDetail.address.city?.name ? ', ' + customerDetail.address.city?.name : ''}${customerDetail.address.state?.name ? ', ' + customerDetail.address.state?.name : ''}` 
    : ''} 
  disabled
/>


                
                </Col>
                <Col xs={6} className=' mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Date Joined:</p>
                <Form.Control type='text' value={customerDetail?.createdAt ? new Date(customerDetail.createdAt).toLocaleDateString('en-CA') : 'N/A'} disabled/>
                
                </Col>

            </Row> 
           
            
            </Card>
            </Col>
            </Row>
            <div>
              <h3 className='mb-3' style={{textDecoration:'underline', color:'#355054', fontWeight:'500'}}>Order History</h3>

              {customerDetail?.orders && customerDetail?.orders?.length > 0 ? 
              <Table hover className='my-3' >
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
          <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'>Order ID</th>
           
              <th style={{fontWeight:'600'}} className='pb-3'> Price</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Delivery LGA</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Status</th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              
          </tr>
      </thead>
          <tbody className='py-2'>
          {customerDetail?.orders?.map((order) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={order?.id || order?._id} >
             
             <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#115F04', cursor:'pointer', textDecoration:'underline' }} onClick={() => navigate(`/admin/orders/${order?.id || order?._id}`)}>{truncateText(`${order?.sku ? order?.sku : order?.id || order?._id}`)}</td>
            
             
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.totalCost?.toLocaleString()}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}> {order?.deliveryType === 'delivery' || order?.address ? capitalizeFirstLetter(`${order?.address?.city}`) : 'In-store pickup'}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(order)}</td>

             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
             {order.orderInfo && order.orderInfo.length > 0 && 
  order.orderInfo[order.orderInfo.length - 1].orderStatus.toLowerCase() !== 'order delivered' && 
  order.orderInfo[order.orderInfo.length - 1].orderStatus.toLowerCase() !== 'order picked up' && (<><img src={edit}/> &nbsp;&nbsp; <img src={deleteIcon}/></>)}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => navigate(`/admin/orders/${order?.id || order?._id}`)}>View</td>
            </tr>
          ))}
          </tbody>
  </Table> :  <div className='fw-medium my-3'><h4>You have no orders</h4></div>}
              </div>
        </div>
}
</div>
    </div>
  )
}

export default CustomerDetails

