import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate, useParams} from 'react-router-dom'
import { Form, Button, Image, Card, Overlay, Popover, Row, Col,ListGroup, Dropdown, DropdownDivider } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useAppContext } from '../../AppContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import deleteIcon from '../../assets/delete.svg'
import { FaTrash} from 'react-icons/fa';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import orderRoute from '../../services/orderRoute';
import { debounce } from 'lodash';
import Select from 'react-select';
import search from '../../assets/search.svg'
import DeleteProductModal from '../../components/Modal/DeleteProductModal';

const AdminOrderDetails = () => {
    const { showToast,setShowDeleteModal } = useAppContext();
    const { orderId } = useParams();
    const [currentOrder, setCurrentOrder] = useState(null);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const [loading, setLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [canModifyOrder, setCanModifyOrder] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        itemsPerPage: 20,
        totalPages: 0,
        totalProducts: 0,
    });
     const [error, setError] = useState("");
    const dropdownRef1 = useRef(null);
    const [recipientName, setRecipientName]= useState(orderDetail?.recipientName || '')
    const {getOrders, getAdminSingleOrder, bulkStatusUpdate, getDeliveryLGAs,getDeliveryStates,editOrder}= orderRoute();
    const [lgaOptions, setLgaOptions] = useState([]);
    const [originalLgaOptions, setOriginalLgaOptions] = useState([]);
  
    const [deliveryStates, setDeliveryStates] = useState([]);
    
  
    useEffect(()=>{
      getDeliveryStates(
        showToast, setDeliveryStates 
      )
    },[])

    useEffect(()=> {
      getAdminSingleOrder (
        orderId, setOrderLoading,showToast, setOrderDetail
      )
    },[])
  

  useEffect(() => {
    if (orderDetail?.cart && orderDetail?.cart?.length > 0 && orderDetail?.deliveryDate && orderDetail?.orderInfo?.length > 0) {
      const lastOrderInfo = orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1];
      if (orderDetail?.cart && orderDetail?.cart?.length > 0 && lastOrderInfo?.orderStatus?.toLowerCase() === 'order confirmed') {
        const deliveryDate = new Date(orderDetail?.deliveryDate);
        deliveryDate?.setDate(deliveryDate?.getDate() - 1);
        deliveryDate?.setHours(23, 0, 0, 0);
        const currentTime = new Date();
        setCanModifyOrder(currentTime < deliveryDate);
       
      }
    }
  }, [orderDetail?.deliveryDate, orderDetail?.cart, orderDetail?.orderInfo]);

    useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
       
        }
    
        function handleScroll() {
          setIsOpen(false);
        
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);
      useEffect(() => {
 
        if (isEditing && orderDetail?.address?.state) {
          const selectedState = deliveryStates.find(state => state.name === orderDetail?.address?.state?.name);
          if (selectedState) {
            getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
            
          }
        }
      }, [orderDetail?.address?.state, deliveryStates,isEditing]);

      const formatDateToLongForm = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      };
      const totalShoppingInstructionsFee = orderDetail?.shoppingInstructions?.reduce((sum, instruction) => sum + instruction?.instruction?.amount, 0) || 0;

      const getShoppingInstructions = (productName, shoppingInstructions) => {
        return shoppingInstructions
          .filter(instruction =>
            instruction.products.some(product => product.productName === productName)
          )
          .map(instruction => instruction.instruction.name)
          .join(', ');
      };
      

      const getStatusStyle = (status) => {
        switch (status) {
          case 'order confirmed':
            return { backgroundColor: 'transparent',fontSize:'10px', color: '#194298', border:'1px solid #194298', padding:'2px 6px', borderRadius:'16px' };
            case 'order delivered':
              return  { backgroundColor: 'transparent',fontSize:'10px', color: '#08875D', border:'1px solid #08875D', padding:'2px 6px',borderRadius:'16px' };
              case 'order picked up':
                return  { backgroundColor: 'transparent',fontSize:'10px', color: '#08875D', border:'1px solid #08875D', padding:'2px 6px',borderRadius:'16px' };
          case 'out for delivery':
            return  { backgroundColor: 'transparent',fontSize:'10px', color: '#F2994A', border:'1px solid #F2994A', padding:'2px 6px',borderRadius:'16px' };
            case 'ready for pickup':
              return  { backgroundColor: 'transparent',fontSize:'10px', color: '#F2994A', border:'1px solid #F2994A', padding:'2px 6px',borderRadius:'16px' };
          case 'order processing':
            return { backgroundColor: 'transparent',fontSize:'10px', color: '#B25E09', border:'1px solid #B25E09', padding:'2px 6px',borderRadius:'16px' };
          case 'awaiting payment':
            return { backgroundColor: 'transparent',fontSize:'10px', color: '#FD7B1D', padding:'2px 6px',borderRadius:'16px',border:'1px solid #FD7B1D' };
          default:
            return { backgroundColor: 'transparent',fontSize:'10px', color: '#B25E09', border:'1px solid #FEF1F2', padding:'2px 6px',borderRadius:'16px' };
        }
      };
      const capitalizeFirstLetter = (input) => {
        if (typeof input !== 'string') {
          return '';  // Return an empty string or some default text
        }
        return input.replace(/\b(\w)/g, s => s.toUpperCase());
      };

      
  const toggleDropdown = () => setIsOpen(!isOpen);
  const isNigerianPhoneNumber = (phoneNumber) => {
    // This regex matches phone numbers starting with '070', '080', '090', '081', and '091'
    return /^0[7-9][0-1]\d{8}$/.test(phoneNumber) || /^091\d{8}$/.test(phoneNumber);
  };
 
  const handleBack = () => {
   
    navigate(-1);
  }
  const truncateText = (text, maxLength = 12) => {
    return text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

const statusTransitions = {
  pickup: {
    "order confirmed": "order processing",
    "order processing": "ready for pickup",
    "ready for pickup": "order picked up"
  },
  delivery: {
    "order confirmed": "order processing",
    "order processing": "out for delivery",
    "out for delivery": "order delivered"
  }
};


const handleStatusUpdate = (status) => {
  const lowerCaseStatus = status.toLowerCase();
  const currentStatus = orderDetail?.orderInfo && orderDetail?.orderInfo.length > 0 ?
    orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1].orderStatus?.toLowerCase() : 'order confirmed';

  const validNextStatus = statusTransitions[orderDetail?.deliveryType][currentStatus];

  if (lowerCaseStatus !== validNextStatus) {
    showToast({
      toastText: `Invalid status update. Expected next status should be ${capitalizeFirstLetter(validNextStatus)}.`,
      isSuccess: false
    });
    return;
  }

  const email = orderDetail?.user ? orderDetail?.user?.email : orderDetail?.metaData?.email;
  const orderId = orderDetail?.id;

  const body = {
    ordersToUpdate: [{
      orderId: orderId,
      email: email,
    }],
    status: lowerCaseStatus
  };

  bulkStatusUpdate(body, setLoading, showToast);
};
const handleEdit = () => {
  setIsEditing(true);
};

const handleModifyOrderClick = () => {
 
  navigate(`/admin/orders/modification/${orderId}`);
  
 
};

const handleChange = (e) => {
  const { name, value } = e.target;

  if (name === "state") {
    const selectedState = deliveryStates.find(state => state.name === value);
    if (selectedState) {
      getDeliveryLGAs(selectedState.id, showToast, setLgaOptions, setOriginalLgaOptions);
    }


    setOrderDetail(prev => ({
      ...prev,
      address: { ...prev.address, state: selectedState }
    }));
    setOrderDetail(prevDetails => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        streetAddress: ''
      }
    }));
  } else if (name === "streetAddress") {
   
    setOrderDetail(prev => ({
      ...prev,
      address: { ...prev.address, [name]: value }
    }));
  } else {
    // Handles all other fields outside of the 'address' object and special cases
    setOrderDetail(prev => ({ ...prev, [name]: value }));
  }
};
  
const formatOptionLabel = ({ label, value }) => {
  if (value === "comingSoon") {
    // Split the label to insert styling on a specific part
    const firstPart = "We are coming to ";
    const secondPart = label.split('soon! ')[0].replace(firstPart, '');
    const thirdPart = "soon! ";
    const actionPart = "Join our waitlist and be the first to know when we do.";
   

    return (
      <div>
        {firstPart}
        <span>{secondPart}</span>
        {thirdPart}
        <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
      
      </div>
    );
  }
  // Return the label as is for other options
  return label;
};

const handleInputChange = (inputValue, actionMeta) => {
  if (actionMeta.action === "input-change") {
    const filteredLgas = originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
    if (filteredLgas.length === 0 && inputValue.trim() !== "") {
      setLgaOptions([{ value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }]);
    } else {
      setLgaOptions(filteredLgas);
    }
  }
};




const handleSelectChange = (selectedOption) => {
  if (!selectedOption) {
  
    setOrderDetail((prevDetails) => ({
      ...prevDetails,
      address:{ ...prevDetails.address,city: {...prevDetails.address.city, name:''}} 
    }));
    setLgaOptions(originalLgaOptions)
  } else if (selectedOption.value === "comingSoon") {
  
    setOrderDetail((prevDetails) => ({
      ...prevDetails,
      address:{ ...prevDetails.address,city: {...prevDetails.address.city, name:''}}
    }));
    
    const selectedState = deliveryStates.find(state => state.name === orderDetail?.address?.state?.name);
    if (selectedState) {
      getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
    }
   
  } else {
   
    setOrderDetail((prevDetails) => ({
      ...prevDetails,
      address:{ ...prevDetails.address, city: {...prevDetails.address.city, name:selectedOption.value}}
    }));
  }
};


const handleSaveDetails = async (e) => {
  e.preventDefault()

  const body={
    deliveryDate: new Date(orderDetail.deliveryDate).toISOString(),
    phoneNumber: orderDetail?.phoneNumber
  }
  
      
      

  const address = {
      streetAddress: orderDetail?.address?.streetAddress,
      city: orderDetail?.address?.city?.name,
      state: orderDetail?.address?.state?.name,
      landmark: orderDetail?.address?.landmark,
  };

  const selectedState = deliveryStates.find(state => state.name === orderDetail?.address?.state?.name);
  const stateId = selectedState ? selectedState.id : null;

  // Extract LGA ID
  const selectedLGA = lgaOptions.find(option => option.label === orderDetail?.address?.city?.name);
  const lgaId = selectedLGA ? selectedLGA.id : null;

  
  

  if (orderDetail?.orderComment !== '') {
    body.orderComment = orderDetail?.orderComment;
}


if (recipientName !== '') {
  body.recipientName = recipientName;
}

  
  if (orderDetail?.deliveryType === 'delivery') {
    body.address = {
      ...address,
      state: stateId, // Pass state ID instead of state name
      city: lgaId, // Pass LGA ID instead of LGA name
    };
  }



editOrder(body,orderId, setLoading,showToast)
}
  return (
    <div>
        <div className='d-flex  justify-content-between align items-center header-admin  py-4 pe-5 mb-4 ps-4'>
        <div className='d-flex gap-3 align-items-center'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow' />
        <h3 style={{color:'#344054'}} className='my-auto'>Order Details</h3>
        {/* {!isEditing && canModifyOrder && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEdit} className='my-3'>Edit</p>
      )} */}
        </div>
        <div className='d-flex gap-2'>
        <Button variant='danger' onClick={() => { setCurrentOrder(orderDetail);
    setShowDeleteModal(true);
}} className='d-flex align-items-center p-2 ' style={{height:'40px'}}>Delete Order</Button>
        {orderDetail?.orderInfo && orderDetail?.orderInfo?.length > 0 && 
  orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1].orderStatus?.toLowerCase() !== 'order delivered' && 
  orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1].orderStatus?.toLowerCase() !== 'order picked up' &&
        <Dropdown show={isOpen} ref={dropdownRef1}>
        <Button id="dropdown-basic"  onClick={toggleDropdown} className='d-flex align-items-center gap-4 p-2 ' style={{height:'40px'}}>
       Change Status <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      {orderDetail?.deliveryType === 'pickup' ?      
      <Dropdown.Menu>
      {[ 'Order Picked up', 'Ready for Pickup', 'Order Processing', 'Order Confirmed'].map((status, index) => (
  <Dropdown.Item
    key={index}
    onClick={() => {handleStatusUpdate(status); toggleDropdown()}}
  > {status}
  </Dropdown.Item>
)) }
   </Dropdown.Menu> 
   : <Dropdown.Menu>
      {[ 'Order Delivered', 'Out for Delivery', 'Order Processing', 'Order Confirmed'].map((status, index) => (
  <Dropdown.Item
    key={index}
    onClick={() => {handleStatusUpdate(status); toggleDropdown()}}
  > {status}
  </Dropdown.Item>
))}
   </Dropdown.Menu> }
      
      </Dropdown>
}
</div>

        </div>
        <div className=' pe-5 ps-4 '>
        {loading || orderLoading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):    <div>
  <Card style={{backgroundColor: '#469B1205',border:'none'}} className='py-3 px-3 mb-5 h-100'>
    <div className='d-flex flex-wrap' style={{gap:'35px'}}>
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Customer Name</h6>
      {orderDetail?.user ?  <h6  style={{color:'#353542', fontWeight:500}}>{orderDetail?.user?.firstName}&nbsp;{orderDetail?.user?.lastName}</h6> :  <h6  style={{color:'#353542', fontWeight:500}}>{orderDetail?.metaData?.userName}</h6>}
      </div>
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Email Address</h6>
      {orderDetail?.user ?  <h6 style={{color:'#353542',fontWeight:500}}>{orderDetail?.user?.email}</h6> :  <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.metaData?.email}</h6>}
      </div>
      {!isEditing && !orderDetail?.voucherCode &&
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Phone Number</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.phoneNumber}</h6>
      </div>}
      {!isEditing && orderDetail.recipientName &&
       <div>
       <h6 style={{color:'#919191', fontWeight:500}}>Recipient Name</h6>
       <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.recipientName}</h6>
       </div>}
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Order ID</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{truncateText(orderDetail?.sku || orderDetail?.id)}</h6>
      </div>
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Order Date</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.orderInfo?.length > 0 ? formatDateToLongForm(orderDetail?.orderInfo[0]?.time) : formatDateToLongForm(orderDetail?.createdAt)}</h6>
      </div>
      {!orderDetail?.voucherCode ? <>
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Order Status</h6>
      <div style={getStatusStyle(
  orderDetail?.orderInfo && orderDetail.orderInfo?.length > 0 ? 
  orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1].orderStatus.toLowerCase() : 
  'awaiting payment'
)} className='mb-2'>
  {orderDetail?.orderInfo && orderDetail?.orderInfo?.length > 0 ? 
    capitalizeFirstLetter(orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1]?.orderStatus) : 
    'Awaiting Payment'
  }
</div>
      </div>

      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Delivery Type</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{capitalizeFirstLetter(orderDetail?.deliveryType)}</h6>
      </div>
    
      {!isEditing && orderDetail?.deliveryType === 'delivery' && 
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Delivery Address</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.address?.streetAddress}<br/>{orderDetail?.address?.city?.name}, {orderDetail?.address?.state?.name}</h6>
      {orderDetail?.address?.landmark &&
      <h6 style={{color:'#353542', fontWeight:500}}><b>Landmark:</b>{orderDetail?.address?.landmark}</h6>}
      </div>}
      {/* {orderDetail?.shoppingInstructions??.length > 0 &&
   <div>
        <h6 style={{color:'#919191', fontWeight:500}}>Shopping Instructions</h6>
   {orderDetail?.shoppingInstructions?.map((instruction) => (
                 <h6 style={{color:'#919191', fontWeight:500}} key={instruction.id} >{instruction.name}</h6>
         
         ))}
  
    
   </div>
   } */}
      {!isEditing &&
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}> {orderDetail?.deliveryType === 'pickup' ? 'Pickup Date' : 'Delivery Date'}</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{formatDateToLongForm(orderDetail?.deliveryDate)}</h6>
      </div>
}
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Payment Method</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{capitalizeFirstLetter(orderDetail?.paymentMethod)}</h6>
      </div></> : <>
     <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Recipient Email</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.recipientEmail}</h6>
      </div>
     
      {orderDetail?.voucherCode &&
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Voucher Code</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.voucherCode}</h6>
      </div>}
      </>}
      <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Total Amount Paid</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.totalCost || orderDetail?.amount)}</h6>
      </div>
     
      {orderDetail?.transactionReference  &&  <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Transaction ID</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{truncateText(orderDetail?.transactionReference)}</h6>
      </div>}

      {orderDetail?.transaction &&  <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Transaction ID</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{truncateText(orderDetail?.transaction)}</h6>
      </div>}

      {!isEditing && orderDetail?.orderComment &&  <div>
      <h6 style={{color:'#919191', fontWeight:500}}>Comment</h6>
      <h6 style={{color:'#353542', fontWeight:500, maxWidth:'250px'}}>{orderDetail?.orderComment}</h6>
      </div>}
    </div>
    {orderDetail?.specialMessage &&
      <div className='mt-3'>
      <h6 style={{color:'#919191', fontWeight:500}}>Special Note</h6>
      <h6 style={{color:'#353542', fontWeight:500}}>{orderDetail?.specialMessage}</h6>
      </div>}
 
           
            
            </Card>
            {/* {isEditing &&
             <Card style={{backgroundColor: '#FCFCFC',border:'none'}} className='py-3 px-3 mb-5 h-100'>
            <Form onSubmit={handleSaveDetails}>
            <Row className=''>
                <Col xs={4} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Recipient Name (if different from sender):</p>
                <Form.Control type='text' name='productName' value={recipientName || orderDetail?.recipientName} onChange={(e)=> setRecipientName(e.target.value)}/>
                </Col>
                <Col xs={4} className='px-2 mb-4'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Phone Number<sup className='text-danger'>*</sup></p>
    <Form.Control
      name='phoneNumber'
      type='text'
      placeholder='Enter phone number'
      value={orderDetail?.phoneNumber}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        if (numericValue.length <= 11) {
          setOrderDetail({ ...orderDetail, phoneNumber: numericValue });
        }
    
        
        if (numericValue.length === 11 && !isNigerianPhoneNumber(numericValue)) {
          setError({...error, phoneNumber: 'Please enter a valid 11-digit Nigerian phone number.'});
        } else {
        
          setError({...error, phoneNumber: ''});
        }
      }}
    />
    {error.phoneNumber && <div className="text-danger" style={{fontSize:'12px'}}>{error.phoneNumber}</div>}
  </Col>
  <Col xs={4} className='px-2 mb-4'>
  
  <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Delivery Comment (optional)</p>
         
            <Form.Control
  type='text' placeholder='Add notes/comments to your order(s)' rows={3} className=' p-2 w-100' style={{border:'1px solid #D7D7D7'}}value={orderDetail?.orderComment} onChange={handleChange}
  disabled={!isEditing}
/>



            </Col>

            {orderDetail?.deliveryType === 'delivery' && <>
              
               
                <Col xs={4} className='px-2 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>State <sup className='text-danger'>*</sup></p>
                <Form.Select
  className="custom-select"
  onChange={handleChange}
  name="state"
  value={orderDetail?.address?.state?.name || ''}
  required

>
  <option value="" disabled>Select a state</option>
  {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}
</Form.Select>
                
                </Col>
                <Col xs={4} className='px-2 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>LGA<sup className='text-danger'>*</sup> (Please select a state first)</p>

             <Select
         formatOptionLabel={formatOptionLabel}
         options={lgaOptions}
         onInputChange={handleInputChange}
         onChange={handleSelectChange}
        required
       
          value={lgaOptions.find(option => option.value === orderDetail?.address?.city?.name || '')}

         name="LGA"
        
         isClearable={true}
         styles={{
          menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto'
          })
        }}
       />
                </Col>
                <Col xs={4} className='px-2 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Street Address <sup className='text-danger'>*</sup></p>
                
      <Form.Control
        type="text"
        placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
        onChange={handleChange}
        name="streetAddress"
        value={orderDetail?.address?.streetAddress} required
      />
 
                </Col>
                <Col xs={4} className='px-2 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Landmark<sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='landmark' value={orderDetail?.address?.landmark} required  onChange={handleChange}/>
                </Col></>}  
                <Col xs={4} className='px-2 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Delivery Date<sup className='text-danger'>*</sup></p>
                <div>
                <DatePicker
          selected={orderDetail?.deliveryDate}
          onChange={(date) => 
            setOrderDetail({...orderDetail, deliveryDate:date})
          }
        
          dateFormat="MMMM d, yyyy" // Ensures the long format
          placeholderText="Select a date"
          className="form-control"
          showPopperArrow={false} 
          required// Optional: Hide the popper arrow
        />
        
        </div>
      </Col>
               
</Row>
               


                   
        <div className='my-3 d-flex gap-2 justify-content-end'>
        <Button className='px-5 py-2' variant='danger' onClick={()=> setIsEditing(false)}>Cancel</Button>
          <Button className='px-5 py-2' style={{backgroundColor: '#469B12', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      

        </Form>
        </Card>
            } */}
          
          <Card style={{backgroundColor: '#469B1205',border:'none'}} className='py-3 h-100'>
            <div className='d-flex justify-content-between px-4 mt-1 mb-3 '>
                <h5 style={{color:'#344054', fontWeight:'600'}}>Item Summary</h5>
                {canModifyOrder && (
                              
                              <h6 
                                onClick={handleModifyOrderClick} 
                                style={{ color: '#469B12', fontWeight: '600', cursor: 'pointer', textDecoration:'underline' }}>
                                Modify Order
                              </h6>
                            
                          )}
              </div>
              {orderDetail?.cart && orderDetail?.cart?.length > 0 &&
          <Row style={{borderBottom:'1px solid #E1E6EF'}} className='px-2 mb-2 mx-2 ' >
          <Col xs={2}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Image</h6>
          </Col>
          <Col xs={4}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Product Name</h6>
          </Col>
         
          <Col xs={1}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>QTY</h6>
          </Col>
          {orderDetail?.shoppingInstructions?.length > 0 &&
          <Col xs={3}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Shopping Instructions</h6>
          </Col>}
          <Col xs={2}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Price </h6>
          </Col>
        </Row>}
        {!orderDetail?.cart && !orderDetail?.boxes  &&
          <Row style={{borderBottom:'1px solid #E1E6EF'}} className='px-2 mb-2 mx-2 ' >
          <Col xs={7}>
            <h6 style={{color:'#353542', fontWeight:'500'}}> 
            {orderDetail?.voucherCode !== '' ? 'Voucher' : 'Box'} Image
            </h6>
          </Col>
         
         
         
        
          <Col xs={3}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Price </h6>
          </Col>
        </Row>}
        {(orderDetail?.boxes && Object.keys(orderDetail?.boxes)?.length > 0)  &&
          <Row style={{borderBottom:'1px solid #E1E6EF'}} className='px-2 mb-2 mx-2 ' >
          <Col xs={7}>
            <h6 style={{color:'#353542', fontWeight:'500'}}> 
           Box Image
            </h6>
          </Col>
         
         
         
          <Col xs={2}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>QTY</h6>
          </Col>
          <Col xs={3}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Price </h6>
          </Col>
        </Row>}
        <div className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.cart?.map((item)=> (
          <>
        <Row  key={item?._id} className='mx-2 pb-1 mt-0 pt-0' style={{borderBottom:'1px solid #E1E6EF'}}>
           <Col xs={2} className='mt-0 pt-0'>
          <img 
        src={item?.productId?.productImage || item?.box?.boxImage} 
        alt={item?.productId?.productName || item?.box?.name} 
        style={{ width: "70px", height: "70px" }} 
      />
          </Col>
         
          <Col xs={4}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>{item?.productId?.productName || item?.box?.name}</h6>
          </Col>
         
          <Col xs={1}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4 ms-2'>{item?.quantity}</h6>
          </Col>
          {orderDetail?.shoppingInstructions?.length > 0 &&
          <Col xs={3}>
            <h5 style={{color:'#353542', fontWeight:'400'}}  className='my-4'> {getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h5>
          </Col>
}
          <Col xs={2}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price || item?.box?.amount)}
 
</h6>

          </Col>
        </Row>
        {item?.box?.items && (
     
     item.box.items.map((boxItem, index) => (
      <Row
        key={boxItem?.id}
        className='pb-0 align-items-center'   style={{ marginBottom: index === item.box.items.length - 1 ? '10px' : '0px'}}
      >
        <Col xs={6} md={4}  className='pb-0 mb-0'>
          <ul style={{ paddingLeft: '20px', gap: '0px' }} className='mb-0 pb-0'>
            <li style={{ color: '#344054', fontWeight: '400' }} className='mb-0 pb-0'>
              {boxItem?.productId?.productName}
            </li>
          </ul>
        </Col>
    
        <Col xs={1} md={1} className='pb-0 mb-0'>
          <ul style={{ paddingLeft: '0px', listStyleType: 'none', gap: '0px' }} className='mb-0 pb-0'>
            <li style={{ color: '#344054', fontWeight: '400' }} className='mb-0 pb-0 ms-2'>
              {boxItem?.quantity}
            </li>
          </ul>
        </Col>
    
        {item?.boxShoppingInstructions?.length > 0 && (
          <Col  xs={2} md={3} className='pb-0 mb-0'>
            <ul style={{ paddingLeft: '0px', listStyleType: 'none', gap: '0px' }} className='mb-0 pb-0'>
              <li style={{ color: '#353542', fontWeight: '400' }} className='mb-0 pb-0'>
                {getShoppingInstructions(boxItem?.productId?.productName, item?.boxShoppingInstructions)}
              </li>
            </ul>
          </Col>
        )}
    
      
      </Row>
    ))
    
    
  )}
        </>
        ))}
         { orderDetail?.boxes && Object.keys(orderDetail?.boxes)?.length > 0 && <>
      <Row className='d-flex align-items-center'> 
      <Col xs={9} md={7} >
      <div className='d-flex gap-3 align-items-center'>
      <div className=''> <img src={orderDetail?.boxes?.box?.boxImage} style={{width:'70px', height:'70px',borderRadius:'12px',}}className=''/></div>
          
           <div className='mt-3'>
             <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{orderDetail?.boxes?.box?.name}</p>
           <div className='m-0 p-0 d-flex gap-2 align-items-center '>
      <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.boxes?.box?.amount)}</p>
      
      
      
      
      </div>
           </div>
         </div>
       </Col>
       <Col xs={2} md={2} className=''>
       <h5 style={{color:'#344054', fontWeight:'400'}} className='my-auto ms-2'>{orderDetail?.boxes?.quantity}</h5>
       </Col>
      
       <Col xs={2} md={3} className=''>
       <h5 style={{color:'#344054', fontWeight:'400'}} className='my-auto ms-2'>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.boxes?.box?.amount)}</h5>
       </Col>
      
      </Row>
      <Row className='px-2 mt-3 mb-0'>
         
         <Col xs={7} md={7}>
         <h6 style={{color:'#353542', fontWeight:'600'}} className=''>Items</h6>
         </Col>
         {orderDetail?.shoppingInstructions?.length > 0 &&
         <Col xs={5} md={5}>
           <h6 style={{color:'#353542', fontWeight:'600'}}>Shopping Instructions</h6>
         </Col>}
        
       </Row>
 <div style={{borderBottom:'1px solid #E0E0E0'}} className='d-flex flex-column gap-4 pb-2'>
        {orderDetail?.boxes?.box?.items?.map((item)=> (
      <Row key={item?._id} className='p-0'>
        
       
        <Col xs={7} md={7}>

        <h6 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{item?.productId?.productName}</h6>
        </Col>
        {orderDetail?.shoppingInstructions?.length > 0 &&
        <Col xs={5} md={5}>
        <h6 style={{color:'#344054', fontWeight:'500'}} className='my-auto'>{getShoppingInstructions(item?.productId?.productName, orderDetail?.shoppingInstructions)}</h6>
        </Col>}
       
      </Row>
    ))}
      
      </div> 
       </> }

{!orderDetail?.cart && !orderDetail?.boxes  &&
      <Row className='d-flex align-items-center px-2'> 
      <Col xs={9} md={7} >
      <div className='d-flex gap-3 align-items-center'>
      <div className=''> <img src={orderDetail?.voucher?.desktopVoucherCover} style={{width:'70px', height:'70px',borderRadius:'12px',}}className=''/></div>
          
           <div className='mt-3'>
             <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{orderDetail?.voucher?.name}</p>
           <div className='m-0 p-0 d-flex gap-2 align-items-center '>
     
      
      
      
      
      </div>
           </div>
         </div>
       </Col>
     
      
       <Col xs={2} md={3} className=''>
       <h5 style={{color:'#344054', fontWeight:'400'}} className='my-auto ms-2'>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.amount)}</h5>
       </Col>
      
      </Row>
        }




        </div>
        <div className='d-flex justify-content-end me-lg-5'>
         
        <div className='my-4 d-flex flex-column gap-2 justify-content-end'>
        {!orderDetail?.voucherCode && <>
        <div className='d-flex gap-3'> 
  <h5 style={{color:'#4F4F4F', width:'130px', fontWeight:'400'}}>Sub-Total:</h5>
  <h5 style={{color:'#4F4F4F', fontWeight:'400'}}>
    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail.discountAmount ? (orderDetail?.totalCost - orderDetail?.fee) + orderDetail?.discountAmount : orderDetail?.totalCost - orderDetail?.fee)}
  </h5>
</div>


          <div className='d-flex gap-3'> 
          {orderDetail?.deliveryType === 'delivery' ?
   <h5 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Shipping fee:</h5> :    <h5 style={{color:'#4F4F4F', width:'135px', fontWeight:'400'}}>Processing fee:</h5>
}
       
            <h5 style={{color:'#4F4F4F', fontWeight:'400'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.fee)}</h5>
          </div>
          {orderDetail?.shoppingInstructions?.length > 0 &&  <div className='d-flex gap-3'> 
          <h4 style={{color:'#4F4F4F', fontWeight:'400', width:'135px'}}>Extra sevices:</h4>
            <h4 style={{color:'#4F4F4F', fontWeight:'400'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalShoppingInstructionsFee)}</h4>
          </div>}
          {orderDetail?.discountAmount !== 0 &&
<div className='d-flex gap-3'> 
  <h5 style={{color:'#4F4F4F', width:'130px', fontWeight:'400'}}>Discount:</h5>
  <h5 style={{color:'#4F4F4F', fontWeight:'400'}}>
    -{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.discountAmount)}
  </h5>
</div>}
</>}
          <div className='d-flex gap-3'> 
          <h5 style={{color:'#4F4F4F', fontWeight:'600', width:'130px'}}>Total:</h5>
            <h5 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(orderDetail?.totalCost || orderDetail?.amount)}</h5>
          </div>
        </div>
        
        </div>
        </Card>
        </div>
}
</div>
<DeleteProductModal  item={currentOrder}
      itemType="order" actionType="delete"
    />

    </div>
  )
}

export default AdminOrderDetails