
import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import arrowLeft from '../../assets/arrow-left.svg'
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import orderRoute from '../../services/orderRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/deactivate.svg'
import calendarGreen from '../../assets/calendar-green.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel, DownloadTableExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import { debounce } from 'lodash';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers } from "react-icons/fa";
import { IoCardOutline } from "react-icons/io5";
import { IoCalendarClearOutline } from "react-icons/io5"

import { HiOutlineSpeakerphone } from 'react-icons/hi';
import adminRoute from '../../services/adminRoute';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';
import productRoute from '../../services/productRoute';




const AdminVouchers = () => {
  const { showToast,showDeleteModal, setShowDeleteModal  } = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [searchVoucher, setSearchVoucher] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [displayVouchers, setDisplayVouchers] = useState([]);
const {getAllVouchers,getGlobalVouchers, filterVoucherByStatus}= productRoute();
  const [loading, setLoading] = useState(false);


  const [searchPerformed, setSearchPerformed] = useState(false);
  const [globalVouchers, setGlobalVouchers] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
 
  const [pagination, setPagination] = useState({
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 0,
      totalVouchers: 0,
  });
  const [isDateOpen, setIsDateOpen] = useState(false);
  const tableRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const [currentVoucher, setCurrentVoucher] = useState(null);
  


 

useEffect(() => {
  // Define a debounced version of the getAdminCustomers function
  const debouncedGetVouchers = debounce((searchVoucher) => {
    getAllVouchers(
          setLoading,
          showToast,
          setVouchers,
          setDisplayVouchers,
          pagination,
          setPagination,
          searchVoucher,
      );
  }, 500);


  if (searchVoucher !== '') {
    setSearchPerformed(true)
      debouncedGetVouchers(searchVoucher);
  } else {
   
      getAllVouchers(
          setLoading,
          showToast,
          setVouchers,
        setDisplayVouchers,
          pagination,
          setPagination,
          
      );
  }


  return () => {
      debouncedGetVouchers.cancel();
  };
}, [pagination.currentPage, searchVoucher]);


 
useEffect(() => {

    getGlobalVouchers(
      setLoading, showToast, setGlobalVouchers
     
    )
      
    }, []);

      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
          if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsOpen2(false);
          }
          if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setIsExportOpen(false);
          }
          // if (dropdownRef4.current && !dropdownRef4.current.contains(event.target)) {
          //   setIsStatusOpen(false);
          // }
          // if (dropdownRef5.current && !dropdownRef5.current.contains(event.target)) {
          //   setIsDateOpen(false);
          // }
          // if (dropdownRef6.current && !dropdownRef6.current.contains(event.target)) {
          //   setIsDiscountTypeOpen(false);
          // }
        }
    
        function handleScroll() {
          setIsExportOpen(false);
          // setIsOpen(false);
          // setIsOpen2(false);
          // setIsStatusOpen(false);
          // setIsvouchersOpen(false);
         
          // setIsDateOpen(false)
          // setIsDiscountTypeOpen(false);
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);

    
      
    
      
      

const toggleDropdown = () => setIsOpen(!isOpen);
const toggleDropdown2 = () => setIsOpen2(!isOpen2);


const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
const handleFocus = () => {
  setIsFocused(true)
};

const handleBlur = () => {
  setIsFocused(false);
};
const handlePageChange = (page) => {
  setPagination(prevState => ({
    ...prevState,
    currentPage: page
  }));
};







const capitalizeFirstLetter = (string) => {
  return string?.replace(/\b(\w)/g, s => s?.toUpperCase());
};
const getStatusStyle = (status) => {
  switch (status) {
    case 'active':
      return { backgroundColor: 'white', color: '#115F04', border:'1px solid #115F04', padding:'6px 16px 6px 16px', borderRadius:'12px' };

    case 'inactive':
      
    return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'12px' };
    default:
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px',borderRadius:'12px' };
  }
};

const determineStatus = (voucher) => {
  let statusText = 'Status Unknown';
  let statusStyle = { backgroundColor: 'grey', color: 'white' };

  if (voucher?.isActive === true) {
    statusText = 'Active';
  } else if (voucher?.isActive === false) {
    statusText = 'Inactive';
  } else {
    statusText = 'Unknown';
  }
  
 

  statusStyle = getStatusStyle(statusText?.toLowerCase());
  return <span style={statusStyle}>{statusText}</span>;
};






const handleCSVExport = () => {
  const csvData = prepareCSVData();
  if (csvData) {
    return (
      <CSVLink data={csvData.data} headers={csvData.headers} filename="voucher-list.csv" className='csv-link'>
        As CSV
      </CSVLink>
    );
  } else {
    console.error("CSV data is not available.");
  }
};

const prepareCSVData = () => {
  if (globalVouchers?.length === 0) {
    console.warn("No products available for CSV export.");
    return null;
  }
  
  const headers = [
  
    { label: 'Voucher Name', key: 'vouchersName' },
    { label: 'Date Created', key: 'dateCreated' },
    { label: 'No of Purchases', key: 'purchases' },
    { label: 'Value', key: 'amount' },
    { label: 'Status', key: 'status' },
    
  ];
  
  const data = globalVouchers?.map(vouchers => ({
    
    vouchersName: vouchers?.name,
    dateCreated: vouchers?.createdAt ? new Date(vouchers?.createdAt).toLocaleDateString('en-CA') : 'N/A',
   
    purchases: vouchers?.noOfPurchases || '-',
    amount: vouchers?.amount, 
    status: vouchers?.status || '-', 
  }));
  
  return { headers, data };
};



const handlePDFExport = () => {
const input = document.getElementById('vouchers-table');
html2canvas(input).then((canvas) => {
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgWidth = 190;
  const imgHeight = canvas.height * imgWidth / canvas.width;
  pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  pdf.save('vouchers.pdf');
});
};

const handleStatusFilter = (status) => {
    let activeStatus;
    if(status === 'Active'){
      activeStatus = true
    }else if(status === 'Inactive'){
      activeStatus = false
    }
    filterVoucherByStatus(activeStatus, setLoading, setDisplayVouchers, showToast, setPagination);
}



const getglobalVouchers = ()=> {
  getAllVouchers(
    setLoading,
    showToast,
    setVouchers,
  setDisplayVouchers,
    pagination,
    setPagination,
    
)
}

return (
  <section className=''>

  <div className=' pe-5 ps-4'>

 {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):displayVouchers?.length > 0 ? <>

  <div className='d-flex align-items-center justify-content-between py-4'>
    <div className='d-flex align-items-center gap-3'>
     
     
 <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'>{ pagination?.totalVouchers !== 0 ? pagination?.totalVouchers : displayVouchers?.length} {pagination?.totalVouchers > 1 || displayVouchers?.length > 1 ? 'vouchers' :'voucher'}</p>


      <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500', width:'300px'}}>
      {!isFocused && searchVoucher === '' && (<>
      <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
  
       </>)}
       {searchVoucher !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchVoucher('')}></i>}
      <Form.Control
      style={{border: 'none', backgroundColor: 'transparent'}}
      placeholder="Search by voucher's name"
      className='w-100'
      value={searchVoucher}
      onFocus={handleFocus}
      onBlur={handleBlur} 
      onChange={(e)=>setSearchVoucher(e.target.value)}
      />
    </div>
    </div>
     
      <div className='d-flex gap-2'>
      <Dropdown show={isOpen2}  ref={dropdownRef2}>
     <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown2} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
     Filter <FontAwesomeIcon icon={faChevronDown} />
     </Button>

    <Dropdown.Menu>
    <Dropdown.Item onClick={()=> {getglobalVouchers();toggleDropdown2()}} className='d-flex justify-content-between align-items-center'>All</Dropdown.Item>
    <DropdownDivider/>
     <Dropdown.Item className='d-flex justify-content-between align-items-center'  onClick={() => {handleStatusFilter('Active'); toggleDropdown2()}}> Active</Dropdown.Item>
          
 
     <DropdownDivider/>
     <Dropdown.Item  className='d-flex justify-content-between align-items-center' onClick={() => {handleStatusFilter('Inactive'); toggleDropdown2()}}> Inactive </Dropdown.Item>
   
    </Dropdown.Menu>
   </Dropdown>
  
<Dropdown show={isExportOpen}  ref={dropdownRef3}>
      <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 px-3 py-2'>
      <img src={exportIcon} />Export
      </Button>
      <Dropdown.Menu>
      <Dropdown.Item>
      <DownloadTableExcel
                    filename="vouchers table"
                    sheet="voucherss"
                    currentTableRef={tableRef.current}
                >
        <button style={{border:'none', backgroundColor:'transparent'}}>As Excel</button>
        </DownloadTableExcel>
        </Dropdown.Item>
        <DropdownDivider />
        <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
        <DropdownDivider />
     
        {handleCSVExport()}
    
    
       
      </Dropdown.Menu>
    </Dropdown>
      </div>
  </div>

  <Table hover className='my-3' ref={tableRef} id="vouchers-table">
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
         
      
              <th style={{fontWeight:'600'}} className='pb-3'>Voucher Name</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Date Created</th>
              <th style={{fontWeight:'600'}} className='pb-3'> No of purchases</th>
              <th style={{fontWeight:'600'}} className='pb-3'> Value</th>
              
              <th style={{fontWeight:'600'}} className='pb-3'>Status</th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              
          </tr>
      </thead>
          <tbody className='py-2'>
          {displayVouchers?.map((voucher) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={voucher?._id} >
             

             
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{voucher?.name}</td> 
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{voucher?.createdAt ? new Date(voucher.createdAt).toLocaleDateString('en-CA') : 'N/A'}</td> 
         <td style={{paddingTop: '15px', paddingBottom: '15px', paddingLeft:'50px', paddingRight:'50px' }}>{voucher?.noOfPurchases ?  voucher?.noOfPurchases : '-'}</td> 
             
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>₦{voucher?.amount ? voucher?.amount?.toLocaleString()  : '0'}</td>
           
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(voucher)}</td>

             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
          <img src={edit} style={{cursor:'pointer'}} onClick={() => navigate(`/admin/vouchers/${voucher?._id}`, {
                      state: { isEditing: true },
                    })
                }/> &nbsp;&nbsp;  <i className={voucher?.isActive === true ? "bi bi-dash-circle" : "bi bi-plus-circle"} style={{cursor:'pointer', color:voucher?.isActive === true ? "red" : "green", fontSize:'1.3rem' }}  onClick={() => { setCurrentVoucher(voucher);
    setShowDeleteModal(true);
}}></i></td>
             <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => navigate(`/admin/vouchers/${voucher?._id}`)}>View</td>
            </tr>
          ))}
          </tbody>
  </Table> 

  { displayVouchers.length > 0 && pagination.totalPages > 1 &&  (

<div className="d-flex justify-content-between align-items-center my-5 pt-5" style={{ height: '50px' }}>
<p style={{ fontWeight: '500', color: '#919EAB' }}>
  Showing {displayVouchers.length} of {pagination.totalVouchers} vouchers
</p>
{pagination.totalPages > 1 && (
  <Pagination className="custom-pagination d-flex gap-2">
    <button
      className="btn btn-outline-secondary pb-0 px-2"
      disabled={pagination.currentPage === 1}
      onClick={() => handlePageChange(pagination.currentPage - 1)}
      style={{
        border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
        color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
      }}
    >
      <h6>
        <i className="bi bi-chevron-left"></i>
      </h6>
    </button>

    {/* First Page */}
    <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
      1
    </Pagination.Item>

    {/* Ellipsis and Page Number Logic */}
    {pagination.totalPages > 1 && (
      <>
        {pagination.currentPage > 3 && <Pagination.Ellipsis disabled />}
        
        {/* Previous Page */}
        {pagination.currentPage > 2 && (
          <Pagination.Item onClick={() => handlePageChange(pagination.currentPage - 1)}>
            {pagination.currentPage - 1}
          </Pagination.Item>
        )}

        {/* Current Page */}
        {pagination.currentPage !== 1 && pagination.currentPage !== pagination.totalPages && (
          <Pagination.Item active>{pagination.currentPage}</Pagination.Item>
        )}

        {/* Next Page */}
        {pagination.currentPage < pagination.totalPages - 1 && (
          <Pagination.Item onClick={() => handlePageChange(pagination.currentPage + 1)}>
            {pagination.currentPage + 1}
          </Pagination.Item>
        )}

        {pagination.currentPage < pagination.totalPages - 2 && <Pagination.Ellipsis disabled />}

        {/* Last Page */}
        <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
          {pagination.totalPages}
        </Pagination.Item>
      </>
    )}

    <button
      className="btn btn-outline-secondary pb-0 px-2"
      disabled={pagination.currentPage === pagination.totalPages}
      onClick={() => handlePageChange(pagination.currentPage + 1)}
      style={{
        border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
        color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
      }}
    >
      <h6>
        <i className="bi bi-chevron-right"></i>
      </h6>
    </button>
  </Pagination>
)}
</div>
)}
</>
 :
 <div className='fw-medium ' style={{padding:'50px 0'}}><h4>There are no vouchers</h4></div>
}
</div>
<DeleteProductModal  item={currentVoucher}
      itemType="voucher" actionType="deactivate"
    />

  </section>

)
}

export default AdminVouchers
