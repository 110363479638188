
import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import orderRoute from '../../services/orderRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/delete.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel, DownloadTableExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DeleteProductModal from '../../components/Modal/DeleteProductModal';


const AdminOrders = () => {
  const { showToast,showDeleteModal, setShowDeleteModal } = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [searchOrder, setSearchOrder] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [visualOrderStatus, setVisualOrderStatus] = useState('All');
  const [isDeliveryTypeOpen, setIsDeliveryTypeOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {getAdminOrders, getGlobalOrders, filterOrdersByStatus, bulkStatusUpdate, filterOrdersByDate,filterOrders} = orderRoute()
  const [globalOrders, setGlobalOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderDisplayDetails, setOrderDisplayDetails] = useState([]);
  const [orderStatus, setOrderStatus] = useState('All');
  const [exportFormat, setExportFormat] = useState(null); 
  const [selectedOrdersId, setSelectedOrdersId] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [categories, setCategories] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]); 
  const [selectedFilters, setSelectedFilters] = useState({
    startDate: null,
    endDate:null,
    status: null,
    deliveryType: null,
  });
  const [pagination, setPagination] = useState({
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 0,
      totalOrders: 0,
  });
    const [currentOrder, setCurrentOrder] = useState(null);
  const tableRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);

  useEffect(() => {
    getAdminOrders(
      setLoading,
      showToast,
      setOrderDetails,
      setOrderDisplayDetails,
      pagination,
      setPagination, 
    );
    
}, [pagination.currentPage]);

   

useEffect(() => {

  getGlobalOrders(
    setLoading, showToast, setGlobalOrders, 
   
  )
    
  }, []);

  function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
          if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsDateOpen(false);
          }
          if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setIsExportOpen(false);
          }
          if (dropdownRef4.current && !dropdownRef4.current.contains(event.target)) {
            setIsStatusOpen(false);
          }
          if (dropdownRef5.current && !dropdownRef5.current.contains(event.target)) {
            setIsDeliveryTypeOpen(false);
          }
        }
    
        function handleScroll() {
          setIsOpen(false);
          setIsOpen2(false);
          setIsStatusOpen(false);
          setIsDateOpen(false);
          setIsExportOpen(false);
          setIsDeliveryTypeOpen(false)
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);

       useEffect(() => {
          if (orderDisplayDetails?.length > 0) {
            const updatedOrderDisplayDetails = orderDisplayDetails?.map(orderDetail => {
              let canModify = false;
        
              if (orderDetail?.cart?.length > 0 && orderDetail?.deliveryDate && orderDetail?.orderInfo?.length > 0) {
                const lastOrderInfo = orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1];
                if (lastOrderInfo?.orderStatus?.toLowerCase() === 'order confirmed') {
                  const deliveryDate = new Date(orderDetail?.deliveryDate);
                  deliveryDate.setDate(deliveryDate.getDate() - 1);
                  deliveryDate.setHours(23, 0, 0, 0);
                  const currentTime = new Date();
                  if (currentTime < deliveryDate) {
                    canModify = true;
                  }
                }
              }
        
              return { ...orderDetail, canModify };
            });
        
          
            if (!isEqual(updatedOrderDisplayDetails, orderDisplayDetails)) {
              setOrderDisplayDetails(updatedOrderDisplayDetails);
            }
          }
        }, [orderDisplayDetails]);

      
      useEffect(() => {
        const filterOrders = () => {
           // Normalize status to lowercase
           const normalizedStatus = orderStatus?.toLowerCase();
          if (normalizedStatus === 'all') {
          
            setOrderDisplayDetails(orderDetails);
            
          } else if (normalizedStatus === 'pending') {
            setOrderDisplayDetails(globalOrders?.filter(order => order.paymentStatus.toLowerCase() === 'pending'));
          } else {
            
            let statusToFilter;
            if (normalizedStatus === 'created') {
              statusToFilter = 'order confirmed';
            } else if (normalizedStatus === 'delivered') {
              statusToFilter = 'order delivered';
            } else {
              // In case of any other specific statuses that need to be mapped
              statusToFilter = normalizedStatus;
            }
    
            // Ensure API is called with the correctly formatted status
            filterOrdersByStatus(statusToFilter?.toLowerCase(), setLoading, setOrderDisplayDetails, showToast);
          }
        };
    
        filterOrders();
      }, [orderStatus]);
      const filterOrdersBySearch = () => {
      
        if (!searchOrder) {
          setOrderDisplayDetails(orderDetails);
        
        } else {
        
          const filteredOrders = globalOrders?.filter(order => {
            const user = order?.user;
            if (user) {
              return (
                user?.firstName?.toLowerCase().includes(searchOrder.toLowerCase()) ||
                user?.lastName?.toLowerCase().includes(searchOrder.toLowerCase())
              );
            } else {
              return order?.metaData?.userName.toLowerCase().includes(searchOrder.toLowerCase());
            }
          });
          if(filteredOrders?.length === 0){
            showToast({toastText:'No name matches your search', isSuccess:false})
            return
          }else{
            setOrderDisplayDetails(filteredOrders);
            setPagination({
              currentPage: 1,
              itemsPerPage: 20,
              totalPages: 0,
              totalProducts: 0,
            })
          }
          
        }
      };
    
      // Create a debounced version of the filterOrdersBySearch function
      const debouncedFilterOrders = debounce(filterOrdersBySearch, 300);
    
      useEffect(() => {
        debouncedFilterOrders();
        return () => debouncedFilterOrders.cancel();
      }, [searchOrder]); 


      useEffect(() => {
        const updatedActiveFilters = [];
      
        if (selectedFilters.startDate !== null) {
         
          updatedActiveFilters.push({
            type: 'startDate',
            value: selectedFilters.startDate.toLocaleDateString(),
          });
        }

        if (selectedFilters.endDate !== null) {
         
          updatedActiveFilters.push({
            type: 'endDate',
            value: selectedFilters.endDate.toLocaleDateString(),
          });
        }
      
        if (selectedFilters.status !== null) {
          updatedActiveFilters.push({
            type: 'status',
            value: selectedFilters.status,
          });
        }
      
        if (selectedFilters.deliveryType !== null) {
          updatedActiveFilters.push({
            type: 'deliveryType',
            value: selectedFilters.deliveryType,
          });
        }
      
        setActiveFilters(updatedActiveFilters);
      }, [selectedFilters]);

      const applyFilters = () => {
        let isoStartDate = '';
        let isoEndDate = '';
        let deliveryType = '';
        let orderStatusType = '';
      
        // Handle Start Date
        if (selectedFilters.startDate) {
          const startOfStartDate = new Date(Date.UTC(
            selectedFilters.startDate.getFullYear(),
            selectedFilters.startDate.getMonth(),
            selectedFilters.startDate.getDate(),
            0, 0, 0, 0
          ));
          isoStartDate = startOfStartDate.toISOString();
        }
      
        // Handle End Date
        if (selectedFilters.endDate) {
          const startOfEndDate = new Date(Date.UTC(
            selectedFilters.endDate.getFullYear(),
            selectedFilters.endDate.getMonth(),
            selectedFilters.endDate.getDate(),
            0, 0, 0, 0
          ));
          isoEndDate = startOfEndDate.toISOString();
        }
      
        // Handle Delivery Type
        if (selectedFilters.deliveryType === 'Home Delivery') {
          deliveryType = 'delivery';
        } else if (selectedFilters.deliveryType === 'In-store Pickup') {
          deliveryType = 'pickup';
        }
      
        // Handle Order Status
        if (selectedFilters.status) {
          const normalizedStatus = selectedFilters.status.toLowerCase();
          if (normalizedStatus === 'confirmed') {
            orderStatusType = 'order confirmed';
          }
          else if (normalizedStatus === 'processing') {
            orderStatusType = 'order processing';
          }  else if (normalizedStatus === 'delivered') {
            orderStatusType = 'order delivered';
          } else {
            orderStatusType = normalizedStatus;
          }
        }
      
        filterOrders(setLoading, setOrderDisplayDetails, showToast, pagination, setPagination, {
          isoStartDate,
          isoEndDate,
          orderStatusType,
          deliveryType,
        });
      };
      
      useEffect(() => {
        if (
          selectedFilters.startDate || 
          selectedFilters.endDate || 
          selectedFilters.status || 
          selectedFilters.deliveryType
        ) {
          applyFilters(); // Apply filters whenever selectedFilters changes
        }
        else if (  !selectedFilters.startDate && 
          !selectedFilters.endDate && 
          !selectedFilters.status && 
          !selectedFilters.deliveryType){
          getAdminOrders(
            setLoading,
            showToast,
            setOrderDetails,
            setOrderDisplayDetails,
            pagination,
            setPagination, 
          );
          
        }
      }, [selectedFilters]);
      

      const handleStatusClick = (status) => {
        if (status?.toLowerCase() === 'all' && orderStatus.toLowerCase() === 'all') {
            setOrderStatus(''); // Reset it briefly
            setTimeout(() => setOrderStatus('All'), 0); // Set back to 'All'
        } else {
            setOrderStatus(status);
        }
        setVisualOrderStatus(status);
        setIsStatusOpen(false)
    };
    
      // const handleFilter = () => {
      //   const startOfStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));
      //   const isoStartDate = startOfStartDate.toISOString();
      
      //   // Set the end date to the beginning of the day in UTC
      //   const startOfEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));
      //   const isoEndDate = startOfEndDate.toISOString();
      //   setIsDateOpen(false);
      //   filterOrdersByDate(isoEndDate, isoStartDate, setLoading, setOrderDisplayDetails, setVisualOrderStatus, showToast);
      //  // Close the date picker dropdown
      // };

      const formatDateToLongForm = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      };

    
      
      const handleCSVExport = () => {
        const csvData = prepareCSVData();
        if (csvData) {
          return (
            <CSVLink
              data={csvData.data}
              headers={csvData.headers}
              filename="order-list.csv"
              className="csv-link"
            >
              As CSV
            </CSVLink>
          );
        } else {
          console.error("CSV data is not available.");
          return null; // Ensure function returns a value or null explicitly if no CSV data.
        }
      };
      
      const prepareCSVData = () => {
        if (globalOrders?.length === 0) {
          console.warn("No orders available for CSV export.");
          return null;
        }
      
        const headers = [
          { label: 'Order ID', key: 'orderId' },
    { label: 'Customer Name', key: 'customerName' },
    { label: 'Customer Email', key: 'email' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Status', key: 'status' },
    { label: 'Order Date', key: 'orderDate' },
    { label: 'Cart Items', key: 'cartItems' }, 
    { label: 'Delivery Address', key: 'deliveryLGA' },
    { label: 'Total Price', key: 'price' },
    { label: 'Pickup/Delivery Date', key: 'deliveryDate' },
    { label: 'Payment Method', key: 'paymentMethod' },
    { label: 'Transaction ID', key: 'transactionID' },
    
       
        ];
      
        const data = globalOrders?.map(order => {
          let statusText = 'Status Unknown'; // Define statusText outside the object
          if (Array.isArray(order.orderInfo) && order.orderInfo.length > 0) {
            const lastStatusObj = order.orderInfo[order.orderInfo.length - 1];
            statusText = capitalizeFirstLetter(lastStatusObj.orderStatus);
          } else if (order.paymentStatus === 'pending') {
            statusText = 'Awaiting Payment';
          }else if (order?.source === 'voucher'){
            statusText = 'Order Confirmed';
          }

          
      
       
        const cartItems = order?.cart?.map(item => ` ${item?.productId?.productName} (${item?.quantity})`).join('; ');


        return {
          orderId: order?.sku || order?._id,
          customerName: order?.user ? `${order?.user?.firstName} ${order?.user?.lastName}` : order?.metaData?.userName,
          email: order?.user ? order?.user?.email : order?.metaData?.email,
          phoneNumber: order?.phoneNumber,
          status: statusText,
          orderDate:  order?.orderInfo?.length > 0 ? formatDateToLongForm(order?.orderInfo[0].time) : formatDateToLongForm(order?.createdAt),
          cartItems,
          deliveryLGA: order.deliveryType === 'delivery' || order?.address ? `${order?.address?.streetAddress}, ${order?.address?.city?.name || '-'}, ${order?.address?.state?.name}` : order?.deliveryType === 'pickup' ? 'In-store pickup' : 'Gift Voucher',
          price: order?.totalCost,
          deliveryDate: order?.deliveryDate ? formatDateToLongForm(order.deliveryDate) : 'N/A',
          paymentMethod: order.paymentMethod,
          transactionID: order?.transactionReference || order?.transaction || 'N/A'
          
        };
      });
      
        return { headers, data };
      };
      
     
      
      

const toggleDropdown = () => setIsOpen(!isOpen);
const toggleDropdown2 = () => setIsOpen2(!isOpen2);

const toggleStatusDropdown = () => setIsStatusOpen(!isStatusOpen);
const toggleDeliveryOpen = () => setIsDeliveryTypeOpen(!isStatusOpen);
const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
const handleFocus = () => {
  setIsFocused(true)
};

const handleBlur = () => {
  setIsFocused(false);
};
const handlePageChange = (page) => {
  setPagination(prevState => ({
    ...prevState,
    currentPage: page
  }));
};
const handleCheckboxChange = (orderId, isChecked, event) => {

  
  if (isChecked) {
      setSelectedOrdersId(prevIds => [...prevIds, orderId]);
  } else {
      setSelectedOrdersId(prevIds => prevIds.filter(id => id !== orderId));
  }
};




const capitalizeFirstLetter = (string) => {
  return string.replace(/\b(\w)/g, s => s.toUpperCase());
};
const getStatusStyle = (status) => {
  switch (status) {
    case 'order confirmed':
      return { backgroundColor: 'white', color: '#194298', border:'1px solid #194298', padding:'6px 16px 6px 16px', borderRadius:'16px' };
      case 'order delivered':
        return  { backgroundColor: 'white', color: '#08875D', border:'1px solid #08875D', padding:'6px 16px 6px 16px',borderRadius:'16px' };
        case 'order picked up':
          return  { backgroundColor: 'white', color: '#08875D', border:'1px solid #08875D', padding:'6px 16px 6px 16px',borderRadius:'16px' };
    case 'out for delivery':
      return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'16px' };
      case 'ready for pickup':
        return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'16px' };
    case 'order processing':
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px',borderRadius:'16px' };
    case 'awaiting payment':
      return { backgroundColor: 'white', color: '#FD7B1D', padding:'6px 16px 6px 16px',borderRadius:'16px',border:'1px solid #FD7B1D' };
    default:
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #FEF1F2', padding:'6px 16px 6px 16px',borderRadius:'16px' };
  }
};

const determineStatus = (order) => {
  let statusText = 'Status Unknown';
  let statusStyle = { backgroundColor: 'grey', color: 'white' };

  if (Array.isArray(order?.orderInfo) && order?.orderInfo.length > 0) {
    const lastStatusObj = order?.orderInfo[order?.orderInfo.length - 1]; // Get the last object
    statusText = capitalizeFirstLetter(lastStatusObj.orderStatus); // Assuming capitalizeFirstLetter function capitalizes each word correctly
  } 
  else if (order?.paymentStatus === 'pending') {
    statusText = 'Awaiting Payment'; // This is already properly capitalized
  }
  else if (order?.source === 'voucher'){
    statusText = 'Order Confirmed';
  }


  statusStyle = getStatusStyle(statusText.toLowerCase()); // Ensure status style is determined correctly
  return <span style={statusStyle}>{statusText}</span>;
};

const truncateText = (text, maxLength = 20) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};
const handleCreate = ()=> {
navigate('/admin/orders/create')
}




const { onDownload } = useDownloadExcel({
  currentTableRef: tableRef.current,
  filename: 'Orders-List',
  sheet: 'Orders Details'
});



const handlePDFExport = () => {
const input = document.getElementById('order-table');
html2canvas(input).then((canvas) => {
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgWidth = 190;
  const imgHeight = canvas.height * imgWidth / canvas.width;
  pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  pdf.save('order-list.pdf');
});
};

// const handleDeliveryClick = (delivery) => {
//   let filteredOrders;
//   if (delivery === 'Home Delivery') {
//     filteredOrders = globalOrders.filter(order => order.deliveryType === 'delivery');
//   } else if (delivery === 'In-store Pickup') {
//     filteredOrders = globalOrders.filter(order => order.deliveryType === 'pickup');
//   }
//   setOrderDisplayDetails(filteredOrders);
//   setPagination({
//     currentPage: 1,
//     itemsPerPage: 20,
//     totalPages: 0,
//     totalOrders: 0,
//   })
// };
const pickupStatuses = new Set(['order picked up', 'ready for pickup', 'order processing', 'order confirmed']);
const deliveryStatuses = new Set(['order delivered', 'out for delivery', 'order processing', 'order confirmed']);
const statusTransitions = {
  pickup: {
    "order confirmed": "order processing",
    "order processing": "ready for pickup",
    "ready for pickup": "order picked up"
  },
  delivery: {
    "order confirmed": "order processing",
    "order processing": "out for delivery",
    "out for delivery": "order delivered"
  }
};


const handleStatusUpdate = (status) => {
  if (selectedOrdersId.length === 0) {
    showToast({ toastText: "No orders selected for status update.", isSuccess: false });
    return;
  }
const lowerCaseStatus = status.toLowerCase();
  const invalidOrders = [];
  const outOfSequenceOrders = [];

  const ordersToUpdate = selectedOrdersId.map(orderId => {
   
    const order = orderDisplayDetails?.find(detail => detail?._id === orderId);
   
    if (!order) return null;

    // Check if the new status is valid for the current delivery type
    if ((order.deliveryType === 'pickup' && !pickupStatuses.has(lowerCaseStatus)) ||
        (order.deliveryType === 'delivery' && !deliveryStatuses.has(lowerCaseStatus))) {
      invalidOrders.push(order);
      return null;
    }

    // Check sequence of status update
    const currentStatus = order?.orderInfo && order?.orderInfo.length > 0 ?
      order?.orderInfo[order?.orderInfo?.length - 1].orderStatus.toLowerCase() : null;
    const validNextStatus = statusTransitions[order.deliveryType]?.[currentStatus];

    if (!validNextStatus || validNextStatus !== lowerCaseStatus) {
      outOfSequenceOrders.push(order);
      return null;
    }

    return {
      orderId: orderId,
      email: order?.user ? order?.user?.email : order?.metaData?.email,
    };
  }).filter(order => order !== null);

  // Notify user of invalid orders by delivery type
  if (invalidOrders.length > 0) {
   
    showToast({
      toastText: 'Invalid status update. Please ensure the orders delivery type matches the status',
      isSuccess: false
    });
    return;
  }

  if (outOfSequenceOrders.length > 0) {
    const expectedNextStatus = statusTransitions[outOfSequenceOrders[0].deliveryType][outOfSequenceOrders[0].orderInfo[outOfSequenceOrders[0].orderInfo.length - 1].orderStatus.toLowerCase()];
    showToast({
      toastText: `Out of sequence status update. The expected next status should be ${capitalizeFirstLetter(expectedNextStatus)}.`,
      isSuccess: false
    });
    return;
  }
  

  console.log('Invalid Orders:', invalidOrders);
console.log('Out of Sequence Orders:', outOfSequenceOrders);
console.log(ordersToUpdate,'order to update')
  // Proceed with the update if all checks pass
  if (ordersToUpdate.length > 0) {
    const body = {
      ordersToUpdate: ordersToUpdate,
      status: lowerCaseStatus,
    };

    bulkStatusUpdate(body, setLoading, showToast, setSelectedOrdersId);
   
  } else {
    showToast({ toastText: "No valid orders to update.", isSuccess: false });
  }
};

const handleModifyOrderClick = (orderId) => {
 
  navigate(`/admin/orders/modification/${orderId}`);
  
 
};
const handleFilterChange = (type, value) => {
  setSelectedFilters((prevFilters) => ({
    ...prevFilters,
    [type]: prevFilters[type] === value ? null : value, 
  }));
};

const handleRemoveFilter = (type) => {
  

  setSelectedFilters((prevFilters) => ({
    ...prevFilters,
    [type]: null,
  }));

};

const clearFilters = () => {
  setSelectedFilters({
    startDate: null,
    endDate:null,
    status: null,
    deliveryType: null,
  });
  setActiveFilters([]); 
  setOrderStatus('All');
  setVisualOrderStatus('All');
  setStartDate(new Date());
  setEndDate(new Date());
 
  getAdminOrders(
    setLoading,
    showToast,
    setOrderDetails,
    setOrderDisplayDetails,
    pagination,
    setPagination, 
  );
  
 
};




return ( 
  <section className=''>
  <div className='d-flex justify-content-between align-items-center header-admin py-5 pe-5 ps-4'>
      <h3 style={{color:'#344054'}}>Orders</h3>
   <div className='d-flex gap-3'>
   <Dropdown show={isExportOpen}  ref={dropdownRef3}>
      <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-4 '>
      Export<img src={exportIcon} />
      </Button>
      <Dropdown.Menu>
      <Dropdown.Item>
      <DownloadTableExcel
                    filename="Orders table"
                    sheet="orders"
                    currentTableRef={tableRef.current}
                >
        <button style={{border:'none', backgroundColor:'transparent'}}>As Excel</button>
        </DownloadTableExcel>
        </Dropdown.Item>
        <DropdownDivider />
        <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
        <DropdownDivider />
     
        {handleCSVExport()}
    
    
       
      </Dropdown.Menu>
    </Dropdown>

    <Button className='d-flex align-items-center gap-3 p-2' onClick={handleCreate}>
    <FontAwesomeIcon icon={faPlus} />
    Create Order 
    </Button>

    
   </div>
  </div>
  <div className='d-flex gap-5 pt-2 my-3 ps-4' style={{borderBottom:'1px solid #EDEDED'}}>
  {['All', 'Created', 'Pending', 'Out for Delivery', 'Delivered'].map((status, index) => (
  <p
    key={index}
    className={`order-item pb-2 mb-0 me-4 ${visualOrderStatus === status ? 'active' : ''}`}
    onClick={() => handleStatusClick(status)}
    style={{ cursor: 'pointer', marginBottom: '5px' }}
  >
    {status}&nbsp; Orders
  </p>
))}

</div>
<div className='ps-4 pe-5'>
<div className='d-flex align-items-center justify-content-between py-4 '>
    <div className='d-flex align-items-center gap-5'>
     
     
{selectedOrdersId.length > 0 ? (
 <Form.Check checked label={`${selectedOrdersId.length} selected`} className='selected-check'/> 
): <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'>{visualOrderStatus === 'All' &&  pagination?.totalOrders !== 0 ? pagination?.totalOrders : orderDisplayDetails?.length} {pagination?.totalOrders?.length > 1 || orderDisplayDetails?.length > 1 ? 'Orders' :'Order'}</p> }

{selectedOrdersId.length > 0 && (
   <Dropdown show={isOpen}  ref={dropdownRef1}>
   <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown} className='d-flex align-items-center gap-2 p-2'  style={{fontWeight:'500', color:'#333333', fontSize:'12px'}}>
  Update Status <FontAwesomeIcon icon={faChevronDown} />
   </Button>

   <Dropdown.Menu>
      {[ 'Order Delivered', 'Out for Delivery', 'Order Picked up', 'Ready for Pickup',  'Order Processing', 'Order Confirmed'].map((status, index) => (
  <Dropdown.Item
    key={index}
    onClick={() => {handleStatusUpdate(status); toggleDropdown()}}
  > {status}
  </Dropdown.Item>
))}
   </Dropdown.Menu>
 </Dropdown>
) }
      <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500', width:'300px'}}>
      {!isFocused && searchOrder === '' && (<>
      <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
  
       </>)}
       {searchOrder !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchOrder('')}></i>}
      <Form.Control
      style={{border: 'none', backgroundColor: 'transparent'}}
      placeholder='Search by customer name'
      className='w-100'
      value={searchOrder}
      onFocus={handleFocus}
      onBlur={handleBlur} 
      onChange={(e)=>setSearchOrder(e.target.value)}
      />
    </div>
    </div>
     
      <div className='d-flex gap-3'>
          {/* <Button variant='secondary' className='d-flex align-items-center gap-4' style={{fontWeight:'500', color:'#333333'}}>Filter<FontAwesomeIcon icon={faChevronDown} style={{fontSize:'0.8rem'}}/></Button> */}
          <Dropdown show={isDateOpen}  ref={dropdownRef2}>
   {/* <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown2} className='d-flex align-items-center gap-4'  style={{fontWeight:'500', color:'#333333'}}>
   Filter By Date <FontAwesomeIcon icon={faChevronDown} />
   </Button> */}
   <Button variant='secondary' id="dropdown-basic" onClick={() => setIsDateOpen(!isDateOpen)} className='d-flex align-items-center gap-1'  style={{fontWeight:'500', color:'#333333'}}>
   Date {isDateOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='me-5' >
 
        {isDateOpen && (
          <div className='d-flex flex-column gap-3 p-3 ' style={{width:'180px',overflowX:'hidden'}}>
            <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={(date) => {setStartDate(date); setSelectedFilters({...selectedFilters,startDate:date})}}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className='w-100' style={{overflowX:'hidden'}}
            />
            </Form.Group>
            <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={(date) => {setEndDate(date); setSelectedFilters({...selectedFilters,endDate:date})}}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className='w-100' style={{overflowX:'hidden'}}
            />
            </Form.Group>
           
            {/* <Button className='text-center w-30' onClick={handleFilter}>Filter</Button> */}
          </div>
        )}
   
  </Dropdown.Menu>
 </Dropdown>

 <Dropdown show={isStatusOpen}  ref={dropdownRef4}> 
 
   <Button variant='secondary' id="dropdown-basic" onClick={toggleStatusDropdown} className='d-flex align-items-center gap-1'  style={{fontWeight:'500', color:'#333333'}}>
   Status {isStatusOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='px-2' style={{width:'200px'}} >
 
    {isStatusOpen && [ 'Confirmed', 'Processing', 'Out for Delivery', 'Ready for Pickup','Order Picked Up', 'Delivered'].map((status, index) => (
  // <Dropdown.Item
  //   key={index}
   
  //   onClick={() => {handleStatusClick(status); toggleDropdown2()}}
  // > {status}
  // </Dropdown.Item>

<Form.Check  key={index} type="checkbox"  label={status}checked={selectedFilters.status === status} onChange={() => {toggleStatusDropdown();handleFilterChange('status', status);}}/>
))}
  </Dropdown.Menu>
 </Dropdown>
 <Dropdown show={isDeliveryTypeOpen}  ref={dropdownRef5}>
 
 <Button variant='secondary' id="dropdown-basic" onClick={toggleDeliveryOpen} className='d-flex align-items-center gap-1'  style={{fontWeight:'500', color:'#333333'}}>
 Delivery Type {isDeliveryTypeOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
 </Button>

<Dropdown.Menu className='px-2'>

  {isDeliveryTypeOpen && [ 'Home Delivery', 'In-store Pickup'].map((delivery, index) => (
    <Form.Check  key={index} type="checkbox"  label={delivery}checked={selectedFilters.deliveryType === delivery} onChange={() => {toggleDeliveryOpen();handleFilterChange('deliveryType', delivery);}}/>
//  <Dropdown.Item
//   key={index}
 
//   onClick={() => {handleDeliveryClick(delivery); toggleDeliveryOpen()}}
// > {delivery}
// </Dropdown.Item> 


))}
</Dropdown.Menu>
</Dropdown>   
      </div>
  </div>
  <div className='my-2 d-flex '>
      
      {activeFilters.length > 0 && (
              <div className="d-flex gap-2 align-items-center">
                <h6 style={{fontWeight:'500'}} className='my-2'>Filter by:</h6>
                {activeFilters.map((filter, index) => (
                 
                    
                    <Button key={index} 
                   
                     classNameame="" style={{borderRadius:'20px', fontSize:'14px', border:'1px solid #4F4F4F4D',backgroundColor:'#EAEAEA33',color:'#333333', fontWeight:'500'}}
                    >
                     {filter.value} &nbsp; <span className='px-1 py-0 align-items-center' style={{borderRadius:'50%', border:"1px solid #333333"}}  onClick={() => handleRemoveFilter(filter.type)}>x</span>
                    </Button>
                  
                ))}
                <Button
                     
                      className="apply-filters-button"
                      onClick={clearFilters}
                      disabled={activeFilters.length === 0} style={{fontSize:'14px',backgroundColor:'#4F4F4F', color:'#FFFFFF'}}
                    >
                      Reset Filter
                    </Button>
              </div>
            )}
      </div>
      </div>
 {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):orderDisplayDetails?.length > 0 ? <div className='ps-4 pe-5'>
 
 
  <Table hover className='my-3 d-none' ref={tableRef} >
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
       
              <th style={{fontWeight:'600'}} className='pb-3'>Order ID</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Customer Name</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Email</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Phone Number</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Status</th>
           
              <th style={{fontWeight:'600'}} className='pb-3'>Order Date</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Cart Items</th>

              <th style={{fontWeight:'600'}} className='pb-3'>Delivery Area</th>
              
              <th style={{fontWeight:'600'}} className='pb-3'> Total Price</th>
             
              <th style={{fontWeight:'600'}} className='pb-3'>Pickup/Delivery Date</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Payment Method</th>
           
              <th style={{fontWeight:'600'}} className='pb-3'> Transaction ID</th>
              
          </tr>
      </thead>
          <tbody className='py-2'>
          {orderDisplayDetails?.map((order) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={order?._id} >
           
             <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#194298', cursor:'pointer', textDecoration:'underline' }} onClick={() => navigate(`/admin/orders/${order?._id}`)}>{truncateText(`${order?.sku ? order?.sku : order?._id}`)}</td>

             {order?.user ? <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#194298', cursor:'pointer'  }}>{order?.user?.firstName}&nbsp;{order?.user?.lastName}</td> : <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.metaData?.userName}</td> }

             {order?.user ? <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#194298', cursor:'pointer'  }}>{order?.user?.email}</td> : <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.metaData?.email}</td> }
             
           <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.phoneNumber}</td> 
           <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(order)}</td>
             
           {order.orderInfo && order.orderInfo.length > 0 ?
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{formatDateToLongForm(order.orderInfo[0].time)}</td>: <td style={{paddingTop: '15px', paddingBottom: '15px' }}>N/A</td>}
           <td>
              {order?.cart?.map(item => `${item?.productId?.productName} (${item?.quantity})`).join('; ') || '-'} 
            </td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}> {order?.deliveryType === 'delivery' && order?.address && Object.keys(order?.address)?.length > 0 ? capitalizeFirstLetter(` ${order?.address?.city?.name ? `${order?.address?.city?.name}, ` : ''} ${order?.address?.state?.name} `) : order?.deliveryType === 'pickup' ? 'In-store pickup' : 'Gift Voucher'}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.totalCost?.toLocaleString() || order?.amount?.toLocaleString()}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}> {order?.deliveryDate ? formatDateToLongForm(order?.deliveryDate) : 'Gift Voucher'}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.paymentMethod || '-'}</td>
             
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.transactionReference || order?.transaction || 'N/A'}</td>
           
            
             
            </tr>
          ))}
          </tbody>
  </Table> 
  <Table hover className='my-3' id="order-table"  >
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
          <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'>Order ID</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Customer Name</th>
              <th style={{fontWeight:'600'}} className='pb-3'> Price</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Delivery Area</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Delivery Date</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Status</th>
              
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              
          </tr>
      </thead>
          <tbody className='py-2'>
          {orderDisplayDetails?.map((order) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={order?._id} >
              <td style={{paddingTop: '15px', paddingBottom: '15px' }}> 
              {order?.source !== 'voucher' && order.orderInfo && order.orderInfo.length > 0 && 
  order.orderInfo[order.orderInfo.length - 1].orderStatus.toLowerCase() !== 'order delivered' && 
  order.orderInfo[order.orderInfo.length - 1].orderStatus.toLowerCase() !== 'order picked up' && (
  <div>
      <Form.Check
          checked={selectedOrdersId.includes(order._id)}
          onChange={(e) => {
              handleCheckboxChange(order._id, e.target.checked);
          }}
      />
  </div>
)}

</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#194298', cursor:'pointer', textDecoration:'underline' }} onClick={() => navigate(`/admin/orders/${order?._id}`)}>{truncateText(`${order?.sku ? order?.sku : order?._id}`)}</td>
             {order?.user ? <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#194298', cursor:'pointer'  }}>{order?.user?.firstName}&nbsp;{order?.user?.lastName}</td> : <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.metaData?.userName}</td> }
             
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{order?.totalCost?.toLocaleString() || order?.amount?.toLocaleString()}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
  {order?.deliveryType === 'delivery' && order?.address && Object.keys(order?.address)?.length > 0 ? 
    capitalizeFirstLetter(
      `${order?.address?.city?.name ? `${order?.address?.city?.name}, ` : ''}${order?.address?.state?.name}`
    ) : 
    order?.deliveryType === 'pickup' ? 
      'In-store pickup' : 
      'Gift Voucher'
  }
</td>
<td style={{paddingTop: '15px', paddingBottom: '15px' }}>{formatDateToLongForm(order?.deliveryDate)}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(order)}</td>

             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
             {order?.canModify && (<><img src={edit}  onClick={(e) =>{e.stopPropagation();  handleModifyOrderClick(order?._id)}} /> &nbsp;&nbsp; </>)} <img src={deleteIcon} onClick={() => { setCurrentOrder(order);
    setShowDeleteModal(true);
}}/></td>
             {/* <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => navigate(`/admin/orders/${order?._id}`)}>View</td> */}
            </tr>
          ))}
          </tbody>
  </Table> 

  {orderStatus === 'All' &&
    orderDetails.length > 0 &&
    pagination.totalPages > 1 && (
      <div className="d-flex justify-content-between align-items-center my-5 pt-5" style={{ height: '50px' }}>
      <p style={{ fontWeight: '500', color: '#919EAB' }}>
        Showing {orderDetails.length} of {pagination.totalOrders} products
      </p>
      {pagination.totalPages > 1 && (
        <Pagination className="custom-pagination d-flex gap-2">
          <button
            className="btn btn-outline-secondary pb-0 px-2"
            disabled={pagination.currentPage === 1}
            onClick={() => handlePageChange(pagination.currentPage - 1)}
            style={{
              border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
              color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
            }}
          >
            <h6>
              <i className="bi bi-chevron-left"></i>
            </h6>
          </button>

          {/* First Page */}
          <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
            1
          </Pagination.Item>

          {/* Ellipsis and Page Number Logic */}
          {pagination.totalPages > 1 && (
            <>
              {pagination.currentPage > 3 && <Pagination.Ellipsis disabled />}
              
              {/* Previous Page */}
              {pagination.currentPage > 2 && (
                <Pagination.Item onClick={() => handlePageChange(pagination.currentPage - 1)}>
                  {pagination.currentPage - 1}
                </Pagination.Item>
              )}

              {/* Current Page */}
              {pagination.currentPage !== 1 && pagination.currentPage !== pagination.totalPages && (
                <Pagination.Item active>{pagination.currentPage}</Pagination.Item>
              )}

              {/* Next Page */}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Pagination.Item onClick={() => handlePageChange(pagination.currentPage + 1)}>
                  {pagination.currentPage + 1}
                </Pagination.Item>
              )}

              {pagination.currentPage < pagination.totalPages - 2 && <Pagination.Ellipsis disabled />}

              {/* Last Page */}
              <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
                {pagination.totalPages}
              </Pagination.Item>
            </>
          )}

          <button
            className="btn btn-outline-secondary pb-0 px-2"
            disabled={pagination.currentPage === pagination.totalPages}
            onClick={() => handlePageChange(pagination.currentPage + 1)}
            style={{
              border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
              color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
            }}
          >
            <h6>
              <i className="bi bi-chevron-right"></i>
            </h6>
          </button>
        </Pagination>
      )}

    </div>
    )}


</div>
 :
 <div className='fw-medium px-5 py-5 '><h4>You have no orders</h4></div>
}

<DeleteProductModal  item={currentOrder}
      itemType="order" actionType="delete"
    />

  </section>

)
}

export default AdminOrders