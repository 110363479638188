
import createAxios from './httpCommon';
import Error from './error';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../mixpanel';
import { transformPath } from '../App';
import { getParsedLocalStorageItem } from '../components/Navbar/Navbar';

import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from '../App';

export default () => {
  const { error } = Error();
  const navigate = useNavigate();
  const userData = getParsedLocalStorageItem('userData');

  const getAllPromotions = (
    setLoading,
    showToast,
    setGlobalPromotions,
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = '/order/promotions/all';
     
      axios.get(url,{
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then((response) => {
         
        
            setGlobalPromotions(response?.data?.promotions);
           setLoading(false)
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const getPromotions= (
    setLoading,
    showToast,
    setPromotionDetails,
    setPromotionDisplayDetails,
    pagination,
    setPagination,
    searchPromotion = '',
   
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    // Adjust pagination if it exists
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/order/promotions/all?pageParam=${currentPage}&sizeParam=${itemsPerPage}`;
      // Append search term to URL if provided
      if (searchPromotion) {
        url += `&search=${searchPromotion}`;
      }
  
      axios.get(url,{
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then((response) => {
      
         
          
          if(searchPromotion && response?.data?.promotions?.generalPromotions?.length === 0 ){
            showToast({toastText:'No name matches your search', isSuccess:false})
          } else if(searchPromotion && response?.data?.promotions?.generalPromotions?.length > 0 ){
           
            setPromotionDisplayDetails(response?.data?.promotions?.generalPromotions);
            if(setPagination){
              setPagination({
                currentPage: 1,
                itemsPerPage: 20,
                totalPages: 0,
                totalPromotions: 0,
              })};
          }
          else{
          
            setPromotionDetails(response?.data?.promotions?.generalPromotions);
            setPromotionDisplayDetails(response?.data?.promotions?.generalPromotions);
  
        
          if (setPagination) {
            setPagination({
              currentPage: response?.data?.promotions?.pagination.currentPage,
              itemsPerPage,
              totalPages: response?.data?.promotions?.pagination.totalPages,
              totalPromotions: response?.data?.promotions?.pagination.totalPromotions,
            });
          }
          }
          setLoading(false)
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };



  const getSinglePromotion = (
  
    promoId, setLoading,showToast, setPromotionDetail
    
    
  ) => {
    
    setLoading(true);
    const token = localStorageGetItem('userToken');
   
    createAxios()
      .then((axios) => {
        axios
        .get(`/order/promotions/${promoId}`, {
            headers: {
              'X-Auth-Token': token,
            },
            
          })
          .then(async (response) => {
         
         
            const currentPath = window.location.pathname;

           setPromotionDetail({
            id:response?.data?.promotion?.id || '',
            promoCode:response?.data?.promotion?.couponCode || '',
        promoName:response?.data?.promotion?.description || '',
        percentage:response?.data?.promotion?.percentage || '',
        promoType:response?.data?.promotion?.promotionType || '',
        discountType:response?.data?.promotion?.discountType || '',
        promoFrequency:response?.data?.promotion?.promotionFrequency || '',
        amount:response?.data?.promotion?.amount || '',
        products:response?.data?.promotion?.products || [],
        usageLimit:response?.data?.promotion?.usageLimit || '',
        minOrderAmount:response?.data?.promotion?.minOrderAmount || '',
        categories:response?.data?.promotion?.productCategory.map(category => category.id) || [],
        startDate:response?.data?.promotion?.startDate || '',
        endDate:response?.data?.promotion?.endDate || '',
          });
           setLoading(false)
          })
         
          .catch((e) => {
            // setLoading(false);
           
               const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
              error(e, setLoading, showToast, retriesDone);
          
          
        
         
            
          });
      });
  };

  const editPromotion = (
    body,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.patch('/order/promotions', body, {headers: {
        'X-Auth-Token': token,
      },
      
    })
        .then((response) => {
          setLoading(false);
          showToast({toastText:'Promotion modified successfully', isSuccess:true})
          navigate('/admin/promotions')

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const filterByPromotionType = (statusFilter, setLoading, setPromoDisplayDetails, showToast,setPagination = null) => {
    setLoading(true)
    const token = localStorageGetItem('userToken');
    createAxios().then((axios) => {
      axios.get(`/order/promotions/all?promotionType=${statusFilter}`,{headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {

        if (response?.data?.promotions?.length === 0){
          setLoading(false)
          showToast({toastText: `No promotions are currently ${statusFilter}.`, isSuccess: false});
        }else{
          setPromoDisplayDetails(response.data.promotions); 
          if(setPagination){
          setPagination({
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 0,
            totalPromotions: 0,
          })};
          setLoading(false)
        }
     
       
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, setLoading, showToast, retriesDone); 
      });
    });
  };

  const filterByStatus = (statusFilter,setLoading, setPromoDisplayDetails, showToast,setPagination = null) => {
    setLoading(true)
    const token = localStorageGetItem('userToken');
    createAxios().then((axios) => {
      axios.get(`/order/promotions/all?status=${statusFilter}`,{headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {

        if (response?.data?.promotions?.length === 0){
          setLoading(false)
          showToast({toastText: `No promotions are currently of ${statusFilter} type.`, isSuccess: false});
        }else{
          setPromoDisplayDetails(response.data.promotions); 
          if(setPagination){
          setPagination({
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 0,
            totalPromotions: 0,
          })};
          setLoading(false)
        }
     
       
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, setLoading, showToast, retriesDone); 
      });
    });
  };
  const filterByDiscountType = (statusFilter,setLoading, setPromoDisplayDetails, showToast,setPagination = null) => {
    setLoading(true)
    const token = localStorageGetItem('userToken');
    createAxios().then((axios) => {
      axios.get(`/order/promotions/all?discountType=${statusFilter}`,{headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {

        if (response?.data?.promotions?.length === 0){
          setLoading(false)
          showToast({toastText: `No promotions are currently ${statusFilter}-off.`, isSuccess: false});
        }else{
          setPromoDisplayDetails(response.data.promotions); 
          if(setPagination){
          setPagination({
            currentPage: 1,
            itemsPerPage: 20,
            totalPages: 0,
            totalPromotions: 0,
          })};
          setLoading(false)
        }
     
       
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, setLoading, showToast, retriesDone); 
      });
    });
  };

  const filterPromotionsByDate = (isoEndDate, isoStartDate, setLoading, setPromoDisplayDetails, showToast,setPagination=null) => {
    const token = localStorageGetItem('userToken');
    setLoading(true)
  
    createAxios().then((axios) => {
      axios.get(`/order/promotions/all?startDate=${isoStartDate}&endDate=${isoEndDate}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
      .then((response) => {
    
        setLoading(false)
        if(response.data.promotions.length === 0){
          showToast({
            toastText: 'No promotions within the dates selected',
            isSuccess: false
          });
        }else{
          setPromoDisplayDetails(response.data.promotions);
          if(setPagination){
            setPagination({
              currentPage: 1,
              itemsPerPage: 20,
              totalPages: 0,
              totalPromotions: 0,
            })};
        
        }
     
      
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, setLoading, showToast, retriesDone); 
      });
    });
  };

  const deactivatePromo = (
    promoId,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.patch(`/order/promotions/${promoId}/disactivate`, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:'Promotion deactivated successfully', isSuccess:true})
          
          setTimeout(() => {

            const currentPath = window.location.pathname;
          
            if (currentPath === '/admin/promotions'){
              window.location.reload();
            }else{
             
            navigate('/admin/promotions')}
        }, 1000);
        

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

  const activatePromo = (
    promoId,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.patch(`/order/promotions/${promoId}/activate`, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:'Promotion activated successfully', isSuccess:true})
          
          setTimeout(() => {

            const currentPath = window.location.pathname;
          
            if (currentPath === '/admin/promotions'){
              window.location.reload();
            }else{
             
            navigate('/admin/promotions')}
        }, 1000);
        

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  const deletePromo = (
    promoId,
    setShowDeleteModal,
    setLoading,
    showToast,
    
 
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      axios.delete(`/order/promotions/${promoId}`, {headers: {
        'X-Auth-Token': token
      },
      
    })
        .then((response) => {
          setLoading(false);
          setShowDeleteModal(false);
          showToast({toastText:'Promotion deleted successfully', isSuccess:true})
          navigate('/admin/promotions')
        

         
        }).catch((e) => {
       
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };


  const createPromotions = (
  
    body, setLoading,showToast
    
    
  ) => {
    
    setLoading(true);
    const token = localStorageGetItem('userToken');
   
    createAxios()
      .then((axios) => {
        axios
        .post('/order/promotions',body, {
            headers: {
              'X-Auth-Token': token,
            },
            
          })
          .then(async (response) => {
          
              setLoading(false);
       
            showToast({
              toastText: 'Promotion created succesfully',
              isSuccess: true
            })
            navigate('/admin/promotions')
           
          })
         
          .catch((e) => {
            // setLoading(false);
           
               const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
              error(e, setLoading, showToast, retriesDone);
          
          
        
         
            
          });
      });
  };

  const getGlobalCustomers = (
    setLoading,
    showToast,
    setGlobalCustomers,
    searchCustomer = '',
  ) => {
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = '/admin/customers/all';
      // Append search term to URL if provided
      if (searchCustomer) {
        url += `?search=${searchCustomer}`;
      }
      axios.get(url,{
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then((response) => {
       
  
       
            setGlobalCustomers(response?.data?.data);
           setLoading(false)
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };

const getAdminCustomers = (
  setLoading,
  showToast,
  setCustomerDetails,
  setCustomerDisplayDetails,
  pagination,
  setPagination,
  searchCustomer = '',
 
) => {
  let currentPage = 1;
  let itemsPerPage = 20;

  // Adjust pagination if it exists
  if (pagination) {
    currentPage = pagination.currentPage;
    itemsPerPage = pagination.itemsPerPage;
  }
  const token = localStorageGetItem('userToken');
  setLoading(true);
  createAxios().then((axios) => {
    let url = `/admin/customers/all?pageParam=${currentPage}&sizeParam=${itemsPerPage}`;
    // Append search term to URL if provided
    if (searchCustomer) {
      url += `&search=${searchCustomer}`;
    }

    axios.get(url,{
      headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {
    
       
        if(searchCustomer && response?.data?.data?.users?.length === 0 ){
          showToast({toastText:'No name matches your search', isSuccess:false})
        }
        else{
        
          setCustomerDetails(response?.data?.data?.users);
          setCustomerDisplayDetails(response?.data?.data?.users);

        // Update pagination state
        if (setPagination) {
          setPagination({
            currentPage: response?.data?.data?.pagination.currentPage,
            itemsPerPage,
            totalPages: response?.data?.data?.pagination.totalPages,
            totalCustomers: response?.data?.data?.pagination.totalUsers,
          });
        }
        }
        setLoading(false);
        
      }).catch((e) => {
        // setLoading(false);
         const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone); 
      });
  });
};

const getSingleAdminCustomers = (
  
  customerId, setLoading,showToast, setCustomerDetail
  
  
) => {
  
  setLoading(true);
  const token = localStorageGetItem('userToken');
 
  createAxios()
    .then((axios) => {
      axios
      .get(`/admin/customer/${customerId}`, {
          headers: {
            'X-Auth-Token': token,
          },
          
        })
        .then(async (response) => {
         
        
       
         setCustomerDetail({
          firstName: response?.data?.data.firstName,
          lastName: response?.data?.data.lastName,
          email: response?.data?.data?.email,
          phoneNumber: response?.data?.data?.phoneNumber,
          address: {
            streetAddress: response?.data?.data?.address?.streetAddress ,
            LGA: response?.data?.data?.address?.city,
            state: response?.data?.data?.address?.state,
          },
          profilePicture:response?.data?.data?.profilePicture,
          createdAt:response?.data?.data?.createdAt,
          orders:response?.data?.data?.orders,
          status:response?.data?.data?.status,
        });
         setLoading(false);
        })
       
        .catch((e) => {
          // setLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
        
      
       
          
        });
    });
};




const getAdmins = (
    setLoading,
    showToast,
    setAdminDetails,
    setAdminDisplayDetails,
    pagination,
    setPagination,
    searchAdmin = '',
   
  ) => {
    let currentPage = 1;
    let itemsPerPage = 20;
  
    // Adjust pagination if it exists
    if (pagination) {
      currentPage = pagination.currentPage;
      itemsPerPage = pagination.itemsPerPage;
    }
    const token = localStorageGetItem('userToken');
    setLoading(true);
    createAxios().then((axios) => {
      let url = `/admin/all?pageParam=${currentPage}&sizeParam=${itemsPerPage}`;
      // Append search term to URL if provided
      if (searchAdmin) {
        url += `&search=${searchAdmin}`;
      }
  
      axios.get(url,{
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then((response) => {
      
       
            setAdminDetails(response?.data?.data?.users);
            setAdminDisplayDetails(response?.data?.data?.users);
  
          // Update pagination state
          if (setPagination) {
            setPagination({
              currentPage: response?.data?.data?.pagination.currentPage,
              itemsPerPage,
              totalPages: response?.data?.data?.pagination.totalPages,
              totalAdmins: response?.data?.data?.pagination.totalUsers,
            });
          }
          setLoading(false)
          
        }).catch((e) => {
          // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone); 
        });
    });
  };
  
  const getSingleAdmins = (
    
    adminId, setLoading,showToast, setAdminDetail
    
    
  ) => {
    
    setLoading(true);
    const token = localStorageGetItem('userToken');
   
    createAxios()
      .then((axios) => {
        axios
        .get(`/admin/details/${adminId}`, {
            headers: {
              'X-Auth-Token': token,
            },
            
          })
          .then(async (response) => {
         
           setAdminDetail(response?.data?.data)
           setLoading(false)
          })
         
          .catch((e) => {
            // setLoading(false);
           
               const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
              error(e, setLoading, showToast, retriesDone);
          
          
        
         
            
          });
      });
  };

  const filterCustomersByDate = (isoEndDate, isoStartDate, setLoading, setCustomerDisplayDetails, showToast,setPagination=null) => {
    const token = localStorageGetItem('userToken');
    setLoading(true)
  
    createAxios().then((axios) => {
      axios.get(`/admin/customers/all?startDate=${isoStartDate}&endDate=${isoEndDate}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
      .then((response) => {
    
        setLoading(false)
        if(response.data.data.length === 0){
          showToast({
            toastText: 'No customers within the dates selected',
            isSuccess: false
          });
        }else{
          setCustomerDisplayDetails(response.data.data);
          if(setPagination){
            setPagination({
              currentPage: 1,
              itemsPerPage: 20,
              totalPages: 0,
              totalCustomers: 0,
            })};
        
        }
     
      
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };

  const filterCustomersBySource = (status, setLoading, setCustomerDisplayDetails, showToast,setPagination=null) => {
    const token = localStorageGetItem('userToken');
    setLoading(true)
  
    createAxios().then((axios) => {
      axios.get(`/admin/customers/all?leadSource=${status}`,{
        headers: {
          'X-Auth-Token': token,
        },
      })
      .then((response) => {
    
        setLoading(false)
        console.log(response)
        if(response.data.data.length === 0){
          showToast({
            toastText: 'No customers with the source selected',
            isSuccess: false
          });
        }else{
          setCustomerDisplayDetails(response.data.data);
          if(setPagination){
            setPagination({
              currentPage: 1,
              itemsPerPage: 20,
              totalPages: 0,
              totalCustomers: 0,
            })};
        
        }
     
      
      })
      .catch((e) => {
  
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
  };


  return {
    
    getAdminCustomers, getSingleAdminCustomers,getGlobalCustomers, getAdmins, getSingleAdmins, filterCustomersByDate, getPromotions, deactivatePromo, filterByStatus,filterByDiscountType,filterPromotionsByDate, getSinglePromotion, deletePromo, createPromotions,editPromotion, filterByPromotionType, getAllPromotions, filterCustomersBySource,activatePromo
    };
};